import { logger } from "@leon-hub/logging";
import { assert, isObject } from "@leon-hub/guards";
import { Timer, Deferred } from "@leon-hub/utils";
import { onWindowVisibilityChanged } from "@leon-hub/browser-composables";
import { CordovaWebSocket } from "@leon-hub/cordova";
import { getLocationHost, isHttps } from "@leon-hub/service-locator-env";
function print(value) {
  return value;
}
var AccountType = /* @__PURE__ */ ((AccountType2) => {
  AccountType2["MAIN"] = "MAIN";
  AccountType2["CBC"] = "CBC";
  AccountType2["BETTING"] = "BETTING";
  AccountType2["LOCKED"] = "LOCKED";
  return AccountType2;
})(AccountType || {});
var AchievementEventType = /* @__PURE__ */ ((AchievementEventType2) => {
  AchievementEventType2["REWARD_REQUESTED"] = "REWARD_REQUESTED";
  AchievementEventType2["REWARD_RECEIVED"] = "REWARD_RECEIVED";
  AchievementEventType2["REWARD_REJECTED"] = "REWARD_REJECTED";
  AchievementEventType2["REWARD_EXPIRED"] = "REWARD_EXPIRED";
  AchievementEventType2["UNKNOWN"] = "UNKNOWN";
  return AchievementEventType2;
})(AchievementEventType || {});
var BetEventType = /* @__PURE__ */ ((BetEventType2) => {
  BetEventType2["LIVE"] = "LIVE";
  BetEventType2["PREMATCH"] = "PREMATCH";
  BetEventType2["ANY"] = "ANY";
  BetEventType2["UNKNOWN"] = "UNKNOWN";
  return BetEventType2;
})(BetEventType || {});
var BetOperationType = /* @__PURE__ */ ((BetOperationType2) => {
  BetOperationType2["BET"] = "BET";
  BetOperationType2["WIN"] = "WIN";
  BetOperationType2["LOSE"] = "LOSE";
  BetOperationType2["ROLLBACK"] = "ROLLBACK";
  BetOperationType2["ROLLBACK_WIN"] = "ROLLBACK_WIN";
  return BetOperationType2;
})(BetOperationType || {});
var BetType = /* @__PURE__ */ ((BetType2) => {
  BetType2["SINGLE"] = "SINGLE";
  BetType2["EXPRESS"] = "EXPRESS";
  BetType2["SYSTEM"] = "SYSTEM";
  BetType2["ANY"] = "ANY";
  BetType2["UNKNOWN"] = "UNKNOWN";
  return BetType2;
})(BetType || {});
var BonusAmountFormula = /* @__PURE__ */ ((BonusAmountFormula2) => {
  BonusAmountFormula2["FIXED_AMOUNT"] = "FIXED_AMOUNT";
  BonusAmountFormula2["DEPOSIT_PERCENT"] = "DEPOSIT_PERCENT";
  BonusAmountFormula2["BET_PERCENT"] = "BET_PERCENT";
  BonusAmountFormula2["UNKNOWN"] = "UNKNOWN";
  return BonusAmountFormula2;
})(BonusAmountFormula || {});
var BonusCloseReason = /* @__PURE__ */ ((BonusCloseReason2) => {
  BonusCloseReason2["SUCCESSFUL"] = "SUCCESSFUL";
  BonusCloseReason2["NO_BALANCE"] = "NO_BALANCE";
  BonusCloseReason2["REFUSED"] = "REFUSED";
  BonusCloseReason2["EXPIRED"] = "EXPIRED";
  BonusCloseReason2["FREESPIN_LOST"] = "FREESPIN_LOST";
  BonusCloseReason2["ERROR"] = "ERROR";
  BonusCloseReason2["UNKNOWN"] = "UNKNOWN";
  return BonusCloseReason2;
})(BonusCloseReason || {});
var BonusRequirementChangeType = /* @__PURE__ */ ((BonusRequirementChangeType2) => {
  BonusRequirementChangeType2["CREATE"] = "CREATE";
  BonusRequirementChangeType2["UPDATE"] = "UPDATE";
  BonusRequirementChangeType2["DELETE"] = "DELETE";
  BonusRequirementChangeType2["UNKNOWN"] = "UNKNOWN";
  return BonusRequirementChangeType2;
})(BonusRequirementChangeType || {});
var BonusType = /* @__PURE__ */ ((BonusType2) => {
  BonusType2["BONUS"] = "BONUS";
  BonusType2["FREESPIN"] = "FREESPIN";
  BonusType2["UNKNOWN"] = "UNKNOWN";
  return BonusType2;
})(BonusType || {});
var ButtonAction = /* @__PURE__ */ ((ButtonAction2) => {
  ButtonAction2["CLAIM_CASHBACK"] = "CLAIM_CASHBACK";
  ButtonAction2["CLOSE"] = "CLOSE";
  return ButtonAction2;
})(ButtonAction || {});
var CampaignType = /* @__PURE__ */ ((CampaignType2) => {
  CampaignType2["EGS"] = "EGS";
  CampaignType2["SPORT"] = "SPORT";
  CampaignType2["UNKNOWN"] = "UNKNOWN";
  return CampaignType2;
})(CampaignType || {});
var CreatedBonusType = /* @__PURE__ */ ((CreatedBonusType2) => {
  CreatedBonusType2["BONUS"] = "BONUS";
  CreatedBonusType2["SIMPLE_FREEBET"] = "SIMPLE_FREEBET";
  CreatedBonusType2["COMPLEX_FREEBET"] = "COMPLEX_FREEBET";
  CreatedBonusType2["UNKNOWN"] = "UNKNOWN";
  return CreatedBonusType2;
})(CreatedBonusType || {});
var DepositOperationType = /* @__PURE__ */ ((DepositOperationType2) => {
  DepositOperationType2["DEPOSIT"] = "DEPOSIT";
  DepositOperationType2["DEPOSIT_CANCEL"] = "DEPOSIT_CANCEL";
  return DepositOperationType2;
})(DepositOperationType || {});
var DepositStatus = /* @__PURE__ */ ((DepositStatus2) => {
  DepositStatus2["UNAVAILABLE"] = "UNAVAILABLE";
  DepositStatus2["NEEDS_DEPOSIT"] = "NEEDS_DEPOSIT";
  DepositStatus2["DEPOSIT_PENDING"] = "DEPOSIT_PENDING";
  DepositStatus2["DEPOSIT_SUCCESS"] = "DEPOSIT_SUCCESS";
  return DepositStatus2;
})(DepositStatus || {});
var DisplayType = /* @__PURE__ */ ((DisplayType2) => {
  DisplayType2["PUSH"] = "PUSH";
  DisplayType2["SILENT"] = "SILENT";
  DisplayType2["SNACKBAR"] = "SNACKBAR";
  return DisplayType2;
})(DisplayType || {});
var FreeBetResultType = /* @__PURE__ */ ((FreeBetResultType2) => {
  FreeBetResultType2["WON"] = "WON";
  FreeBetResultType2["LOST"] = "LOST";
  FreeBetResultType2["LOST_EXPIRED"] = "LOST_EXPIRED";
  FreeBetResultType2["LOST_REFUSED"] = "LOST_REFUSED";
  FreeBetResultType2["UNKNOWN"] = "UNKNOWN";
  return FreeBetResultType2;
})(FreeBetResultType || {});
var FreeBetTransferType = /* @__PURE__ */ ((FreeBetTransferType2) => {
  FreeBetTransferType2["WIN"] = "WIN";
  FreeBetTransferType2["BONUS"] = "BONUS";
  FreeBetTransferType2["WIN_PLUS_BONUS"] = "WIN_PLUS_BONUS";
  FreeBetTransferType2["UNKNOWN"] = "UNKNOWN";
  return FreeBetTransferType2;
})(FreeBetTransferType || {});
var FreeBetType = /* @__PURE__ */ ((FreeBetType2) => {
  FreeBetType2["AMOUNT"] = "AMOUNT";
  FreeBetType2["PERCENT"] = "PERCENT";
  FreeBetType2["FIXED_AMOUNT"] = "FIXED_AMOUNT";
  FreeBetType2["UNKNOWN"] = "UNKNOWN";
  return FreeBetType2;
})(FreeBetType || {});
var FreeSpinResultType = /* @__PURE__ */ ((FreeSpinResultType2) => {
  FreeSpinResultType2["WIN"] = "WIN";
  FreeSpinResultType2["LOSE"] = "LOSE";
  FreeSpinResultType2["LOST_REFUSED"] = "LOST_REFUSED";
  FreeSpinResultType2["LOST_EXPIRED"] = "LOST_EXPIRED";
  FreeSpinResultType2["UNKNOWN"] = "UNKNOWN";
  return FreeSpinResultType2;
})(FreeSpinResultType || {});
var FreeSpinType = /* @__PURE__ */ ((FreeSpinType2) => {
  FreeSpinType2["FLEXIBLE"] = "FLEXIBLE";
  FreeSpinType2["PROVIDER"] = "PROVIDER";
  FreeSpinType2["UNKNOWN"] = "UNKNOWN";
  return FreeSpinType2;
})(FreeSpinType || {});
var FrontNotificationType = /* @__PURE__ */ ((FrontNotificationType2) => {
  FrontNotificationType2["CBC_BONUS_ACTIVATED"] = "CBC_BONUS_ACTIVATED";
  FrontNotificationType2["ALCR_NOTIFICATION"] = "ALCR_NOTIFICATION";
  FrontNotificationType2["VIDEO_VERIFICATION"] = "VIDEO_VERIFICATION";
  FrontNotificationType2["ON_SITE"] = "ON_SITE";
  FrontNotificationType2["REFPRG"] = "REFPRG";
  FrontNotificationType2["UNKNOWN"] = "UNKNOWN";
  FrontNotificationType2["PLAY_TIME_ENDED"] = "PLAY_TIME_ENDED";
  FrontNotificationType2["BONUS_GAME_REWARD"] = "BONUS_GAME_REWARD";
  FrontNotificationType2["BONUS_GAME_LAST_ROUND_FEEDBACK"] = "BONUS_GAME_LAST_ROUND_FEEDBACK";
  FrontNotificationType2["BONUS_EXPIRED_CASHBACK"] = "BONUS_EXPIRED_CASHBACK";
  FrontNotificationType2["BONUS_CASHBACK"] = "BONUS_CASHBACK";
  FrontNotificationType2["SUSPENDED_CASHBACK"] = "SUSPENDED_CASHBACK";
  FrontNotificationType2["RESUMED_CASHBACK"] = "RESUMED_CASHBACK";
  FrontNotificationType2["CRYPTO_DEPOSIT_STATUS"] = "CRYPTO_DEPOSIT_STATUS";
  FrontNotificationType2["BS_DEPOSIT"] = "BS_DEPOSIT";
  FrontNotificationType2["FOMO_BONUS_CREATED"] = "FOMO_BONUS_CREATED";
  return FrontNotificationType2;
})(FrontNotificationType || {});
var IdentificationLevel = /* @__PURE__ */ ((IdentificationLevel2) => {
  IdentificationLevel2["NONE"] = "NONE";
  IdentificationLevel2["SIMPLE"] = "SIMPLE";
  IdentificationLevel2["FULL"] = "FULL";
  IdentificationLevel2["UNKNOWN"] = "UNKNOWN";
  return IdentificationLevel2;
})(IdentificationLevel || {});
var InternalSportBonusType = /* @__PURE__ */ ((InternalSportBonusType2) => {
  InternalSportBonusType2["BONUS"] = "BONUS";
  InternalSportBonusType2["FREEBET"] = "FREEBET";
  InternalSportBonusType2["UNKNOWN"] = "UNKNOWN";
  return InternalSportBonusType2;
})(InternalSportBonusType || {});
var LoyaltyCustomerLevelType = /* @__PURE__ */ ((LoyaltyCustomerLevelType2) => {
  LoyaltyCustomerLevelType2["BALANCE_CHANGE"] = "BALANCE_CHANGE";
  LoyaltyCustomerLevelType2["CUSTOMER_LEVEL_UPGRADE"] = "CUSTOMER_LEVEL_UPGRADE";
  LoyaltyCustomerLevelType2["DEFAULT_LEVEL_INIT"] = "DEFAULT_LEVEL_INIT";
  LoyaltyCustomerLevelType2["CUSTOMER_LEVEL_RECALCULATE"] = "CUSTOMER_LEVEL_RECALCULATE";
  LoyaltyCustomerLevelType2["UNKNOWN"] = "UNKNOWN";
  return LoyaltyCustomerLevelType2;
})(LoyaltyCustomerLevelType || {});
var OfferStatus = /* @__PURE__ */ ((OfferStatus2) => {
  OfferStatus2["UPCOMING"] = "UPCOMING";
  OfferStatus2["ACTIVE"] = "ACTIVE";
  OfferStatus2["ARCHIVED"] = "ARCHIVED";
  OfferStatus2["UNKNOWN"] = "UNKNOWN";
  return OfferStatus2;
})(OfferStatus || {});
var OrderStatus = /* @__PURE__ */ ((OrderStatus2) => {
  OrderStatus2["CREATED"] = "CREATED";
  OrderStatus2["IN_PROGRESS"] = "IN_PROGRESS";
  OrderStatus2["DONE"] = "DONE";
  OrderStatus2["CANCELLED"] = "CANCELLED";
  OrderStatus2["UNKNOWN"] = "UNKNOWN";
  return OrderStatus2;
})(OrderStatus || {});
var ProgramRequirementType = /* @__PURE__ */ ((ProgramRequirementType2) => {
  ProgramRequirementType2["REGISTRATION"] = "REGISTRATION";
  ProgramRequirementType2["DEPOSIT"] = "DEPOSIT";
  ProgramRequirementType2["BET"] = "BET";
  ProgramRequirementType2["CONFIRMATION"] = "CONFIRMATION";
  ProgramRequirementType2["IDENTIFICATION"] = "IDENTIFICATION";
  return ProgramRequirementType2;
})(ProgramRequirementType || {});
var ProgramRewardType = /* @__PURE__ */ ((ProgramRewardType2) => {
  ProgramRewardType2["FREESPIN"] = "FREESPIN";
  ProgramRewardType2["FREEBET"] = "FREEBET";
  return ProgramRewardType2;
})(ProgramRewardType || {});
var RefProgramNotificationType = /* @__PURE__ */ ((RefProgramNotificationType2) => {
  RefProgramNotificationType2["PROGRAM_AVAILABLE"] = "PROGRAM_AVAILABLE";
  RefProgramNotificationType2["PROGRAM_UNAVAILABLE"] = "PROGRAM_UNAVAILABLE";
  RefProgramNotificationType2["REFERRER_REQUIREMENT_SATISFIED"] = "REFERRER_REQUIREMENT_SATISFIED";
  RefProgramNotificationType2["REFERRER_REWARD_ASSIGNED"] = "REFERRER_REWARD_ASSIGNED";
  RefProgramNotificationType2["REFERRAL_REQUIREMENT_SATISFIED"] = "REFERRAL_REQUIREMENT_SATISFIED";
  RefProgramNotificationType2["REFERRAL_REWARD_ASSIGNED"] = "REFERRAL_REWARD_ASSIGNED";
  RefProgramNotificationType2["REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION"] = "REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION";
  RefProgramNotificationType2["BONUS_ELIGIBILITY_RULES"] = "BONUS_ELIGIBILITY_RULES";
  return RefProgramNotificationType2;
})(RefProgramNotificationType || {});
var RewardType = /* @__PURE__ */ ((RewardType2) => {
  RewardType2["FREEBET"] = "FREEBET";
  RewardType2["FREESPIN"] = "FREESPIN";
  RewardType2["BONUS"] = "BONUS";
  RewardType2["MULTIPLIER"] = "MULTIPLIER";
  RewardType2["SUPER_PRIZE"] = "SUPER_PRIZE";
  RewardType2["EMPTY"] = "EMPTY";
  return RewardType2;
})(RewardType || {});
var SportBonusActivatedType = /* @__PURE__ */ ((SportBonusActivatedType2) => {
  SportBonusActivatedType2["BONUS"] = "BONUS";
  SportBonusActivatedType2["SIMPLE_FREEBET"] = "SIMPLE_FREEBET";
  SportBonusActivatedType2["COMPLEX_FREEBET"] = "COMPLEX_FREEBET";
  SportBonusActivatedType2["UNKNOWN"] = "UNKNOWN";
  return SportBonusActivatedType2;
})(SportBonusActivatedType || {});
var SportBonusCloseReason = /* @__PURE__ */ ((SportBonusCloseReason2) => {
  SportBonusCloseReason2["SUCCESSFUL"] = "SUCCESSFUL";
  SportBonusCloseReason2["REFUSED"] = "REFUSED";
  SportBonusCloseReason2["EXPIRED"] = "EXPIRED";
  SportBonusCloseReason2["ERROR"] = "ERROR";
  SportBonusCloseReason2["DELETED"] = "DELETED";
  SportBonusCloseReason2["UNKNOWN"] = "UNKNOWN";
  return SportBonusCloseReason2;
})(SportBonusCloseReason || {});
var SportBonusConfirmationType = /* @__PURE__ */ ((SportBonusConfirmationType2) => {
  SportBonusConfirmationType2["SIMPLE"] = "SIMPLE";
  SportBonusConfirmationType2["BONUS_CODE"] = "BONUS_CODE";
  SportBonusConfirmationType2["UNKNOWN"] = "UNKNOWN";
  return SportBonusConfirmationType2;
})(SportBonusConfirmationType || {});
var SportBonusOperationType = /* @__PURE__ */ ((SportBonusOperationType2) => {
  SportBonusOperationType2["PLACE"] = "PLACE";
  SportBonusOperationType2["VOID"] = "VOID";
  SportBonusOperationType2["CANCEL_VOID"] = "CANCEL_VOID";
  SportBonusOperationType2["WIN"] = "WIN";
  SportBonusOperationType2["CANCEL_WIN"] = "CANCEL_WIN";
  SportBonusOperationType2["LOSE"] = "LOSE";
  SportBonusOperationType2["CANCEL_LOSS"] = "CANCEL_LOSS";
  SportBonusOperationType2["CASH_OUT"] = "CASH_OUT";
  SportBonusOperationType2["CANCEL_CASH_OUT"] = "CANCEL_CASH_OUT";
  SportBonusOperationType2["DEPOSIT"] = "DEPOSIT";
  SportBonusOperationType2["DEPOSIT_CANCEL"] = "DEPOSIT_CANCEL";
  SportBonusOperationType2["UNKNOWN"] = "UNKNOWN";
  return SportBonusOperationType2;
})(SportBonusOperationType || {});
var SportBonusRequirementChangeOperationType = /* @__PURE__ */ ((SportBonusRequirementChangeOperationType2) => {
  SportBonusRequirementChangeOperationType2["ADDED"] = "ADDED";
  SportBonusRequirementChangeOperationType2["DELETED"] = "DELETED";
  SportBonusRequirementChangeOperationType2["UNKNOWN"] = "UNKNOWN";
  return SportBonusRequirementChangeOperationType2;
})(SportBonusRequirementChangeOperationType || {});
var SportBonusRequirementType = /* @__PURE__ */ ((SportBonusRequirementType2) => {
  SportBonusRequirementType2["DEPOSIT"] = "DEPOSIT";
  SportBonusRequirementType2["BET_COUNT"] = "BET_COUNT";
  SportBonusRequirementType2["REGISTRATION"] = "REGISTRATION";
  SportBonusRequirementType2["IDENTIFICATION"] = "IDENTIFICATION";
  SportBonusRequirementType2["CONFIRMATION"] = "CONFIRMATION";
  SportBonusRequirementType2["INSTANT_BONUS_CREATE"] = "INSTANT_BONUS_CREATE";
  SportBonusRequirementType2["START_CAMPAIGN"] = "START_CAMPAIGN";
  SportBonusRequirementType2["BONUS_CODE_CONFIRMATION"] = "BONUS_CODE_CONFIRMATION";
  SportBonusRequirementType2["PARTICIPANT_ADDED"] = "PARTICIPANT_ADDED";
  SportBonusRequirementType2["UNKNOWN"] = "UNKNOWN";
  return SportBonusRequirementType2;
})(SportBonusRequirementType || {});
var SportBonusWagerResultType = /* @__PURE__ */ ((SportBonusWagerResultType2) => {
  SportBonusWagerResultType2["SUCCESSFUL"] = "SUCCESSFUL";
  SportBonusWagerResultType2["CANCELED_EXPIRED"] = "CANCELED_EXPIRED";
  SportBonusWagerResultType2["CANCELED_BY_ERROR"] = "CANCELED_BY_ERROR";
  SportBonusWagerResultType2["CANCELED_REFUSED"] = "CANCELED_REFUSED";
  SportBonusWagerResultType2["UNKNOWN"] = "UNKNOWN";
  return SportBonusWagerResultType2;
})(SportBonusWagerResultType || {});
var TicketChatEventType = /* @__PURE__ */ ((TicketChatEventType2) => {
  TicketChatEventType2["CREATE"] = "CREATE";
  TicketChatEventType2["UNKNOWN"] = "UNKNOWN";
  return TicketChatEventType2;
})(TicketChatEventType || {});
var VerificationStatus = /* @__PURE__ */ ((VerificationStatus2) => {
  VerificationStatus2["UNAVAILABLE"] = "UNAVAILABLE";
  VerificationStatus2["NEEDS_VERIF"] = "NEEDS_VERIF";
  VerificationStatus2["VERIF_PENDING"] = "VERIF_PENDING";
  VerificationStatus2["VERIFIED"] = "VERIFIED";
  return VerificationStatus2;
})(VerificationStatus || {});
var VideoVerificationStatus = /* @__PURE__ */ ((VideoVerificationStatus2) => {
  VideoVerificationStatus2["NONE"] = "NONE";
  VideoVerificationStatus2["PENDING"] = "PENDING";
  VideoVerificationStatus2["IGNORED"] = "IGNORED";
  VideoVerificationStatus2["INVITED"] = "INVITED";
  VideoVerificationStatus2["NO_ANSWER"] = "NO_ANSWER";
  VideoVerificationStatus2["SCHEDULED"] = "SCHEDULED";
  VideoVerificationStatus2["ON_REVIEW"] = "ON_REVIEW";
  VideoVerificationStatus2["PASSED"] = "PASSED";
  VideoVerificationStatus2["NOT_PASSED"] = "NOT_PASSED";
  VideoVerificationStatus2["REFUSED"] = "REFUSED";
  VideoVerificationStatus2["MISSED"] = "MISSED";
  VideoVerificationStatus2["ACCEPT_IGNORE"] = "ACCEPT_IGNORE";
  VideoVerificationStatus2["ACCEPT_REFUSAL"] = "ACCEPT_REFUSAL";
  VideoVerificationStatus2["UNKNOWN"] = "UNKNOWN";
  return VideoVerificationStatus2;
})(VideoVerificationStatus || {});
var VipFastTrackStatus = /* @__PURE__ */ ((VipFastTrackStatus2) => {
  VipFastTrackStatus2["DISABLED"] = "DISABLED";
  VipFastTrackStatus2["NOT_LOGGED_IN"] = "NOT_LOGGED_IN";
  VipFastTrackStatus2["AVAILABLE"] = "AVAILABLE";
  VipFastTrackStatus2["VIP"] = "VIP";
  return VipFastTrackStatus2;
})(VipFastTrackStatus || {});
var WagerResultType = /* @__PURE__ */ ((WagerResultType2) => {
  WagerResultType2["COMPLETED"] = "COMPLETED";
  WagerResultType2["REFUSED"] = "REFUSED";
  WagerResultType2["EMPTY_BALANCE"] = "EMPTY_BALANCE";
  WagerResultType2["EXPIRED"] = "EXPIRED";
  return WagerResultType2;
})(WagerResultType || {});
var WalletType = /* @__PURE__ */ ((WalletType2) => {
  WalletType2["MAIN"] = "MAIN";
  WalletType2["CBC"] = "CBC";
  return WalletType2;
})(WalletType || {});
const onAchievementEventDocument = `
    subscription onAchievementEvent {
  onAchievementEvent {
    eventType
    ... on AchievementRewardEvent {
      rewardId
    }
  }
}
    `;
const onBalanceChangeDocument = `
    subscription onBalanceChange {
  onBalanceChange {
    date
    walletTypeId
    availableBalance
  }
}
    `;
const onBonusBalanceChangeDocument = `
    subscription onBonusBalanceChange {
  onBonusBalanceChange {
    date
    availableBalance
    remainingAmountNumber
    progress
    progressPercentString
  }
}
    `;
const onBonusGameRoundEventDocument = `
    subscription onBonusGameRoundEvent {
  onBonusGameRoundEvent {
    startDate
    endDate
    id
    additional
  }
}
    `;
const onCalculatedCashbackDocument = `
    subscription onCalculatedCashback {
  onCalculatedCashback {
    campaignId
    currency
    amount
    roundId
  }
}
    `;
const onDepositDocument = `
    subscription onDeposit {
  onDeposit {
    firstDeposit
    operationType
    paymentId
    paymentSystemAbbrev
    systemAmount
  }
}
    `;
const onFfsBetCountLimitDocument = `
    subscription onFfsBetCountLimit {
  onFfsBetCountLimit {
    amount
    currency
    channel
    internalGameId
    walletTypeId
    freespinTargetWalletTypeId
  }
}
    `;
const onFfsBetLimitDocument = `
    subscription onFfsBetLimit {
  onFfsBetLimit {
    amount
    currency
    channel
    internalGameId
    walletTypeId
    freespinTargetWalletTypeId
  }
}
    `;
const onFreeSpinResultDocument = `
    subscription onFreeSpinResult {
  onFreeSpinResult {
    id
    campaignId
    result
    amount
    createdAt
    freespinType
    freespinId
    freespinTargetWalletType
  }
}
    `;
const onFrontNotificationDocument = `
    subscription onFrontNotification {
  onFrontNotification {
    id
    type
    timestamp
    isDelivered
    delay
    restrictedRoutes
    ... on BonusActivationNotification {
      amount
      depositAmount
      depositTransferAmount
      currency
      actionUrl
      categoryId
      promoName
    }
    ... on AlcrNotification {
      title
      message
      ctaButtonLink
      ctaButtonText
      imageUrl
      displayType
      iconName
      buttonAction
    }
    ... on VideoVerificationNotification {
      status
      message
    }
    ... on RefProgramNotification {
      payload {
        type
        ... on ProgramAvailable {
          programId
          referrerId
        }
        ... on BonusEligibilityRules {
          currency
          rewards {
            depositAmount
            rewardAmount {
              playerReward {
                count
                nominalValue
                amount
                rewardType
              }
              referralReward {
                count
                nominalValue
                amount
                rewardType
              }
            }
          }
          referralRequirements {
            type
            satisfied
            requiredValue
            currency
          }
        }
        ... on ReferralRewardAssigned {
          referrerId
          referralId
          programId
          reward {
            count
            nominalValue
            amount
            rewardType
          }
        }
        ... on ReferralRewardAssignedReferrerNotificationDto {
          programId
          reward {
            count
            nominalValue
            amount
            rewardType
          }
        }
      }
    }
    ... on PlayTimeTimerEndedNotification {
      hours
      minutes
      overallProfit
      currency
    }
    ... on CryptoDepositStatusNotification {
      amountStr
      currency
      initialTimestamp
      delay
    }
    ... on DepositNotification {
      amount
      currency
      date
      delay
    }
    ... on FomoBonusCreated {
      message
      bonusCampaignName
      bonusCampaignId
      bonusId
      categoryId
      actionUrl
      terms
      availableBefore
      availableBeforeStr
    }
  }
}
    `;
const onLoyaltyCustomerLevelDocument = `
    subscription onLoyaltyCustomerLevel {
  onLoyaltyCustomerLevel {
    type
    currentLevelId
    currentPoints
    multiplier
    pointsUntilNextLevel
  }
}
    `;
const onRefusedCashbackDocument = `
    subscription onRefusedCashback {
  onRefusedCashback {
    campaignId
    roundId
  }
}
    `;
const onTicketChatEventDocument = `
    subscription onTicketChatEvent {
  onTicketChatEvent {
    eventType
    date
  }
}
    `;
const onUpdatedCashbackDocument = `
    subscription onUpdatedCashback {
  onUpdatedCashback {
    campaignId
    amount
    currency
    roundId
  }
}
    `;
const onVipFastTrackStateChangeEventDocument = `
    subscription onVipFastTrackStateChangeEvent {
  onVipFastTrackStateChangeEvent {
    state {
      verificationStatus
      depositStatus
      depositCurrency
      status
      depositAmount
    }
  }
}
    `;
const onWagerCreatedDocument = `
    subscription onWagerCreated {
  onWagerCreated {
    wagerSourceWalletType
  }
}
    `;
const onWagerResultDocument = `
    subscription onWagerResult {
  onWagerResult {
    resultType
  }
}
    `;
const onWagerUpdatedDocument = `
    subscription onWagerUpdated {
  onWagerUpdated {
    currentWagerAmount
    currencyCode
    campaignId
  }
}
    `;
function getWsSdk() {
  return {};
}
const webSocketDocuments = {
  onAchievementEvent: /* @__PURE__ */ print(onAchievementEventDocument),
  onBalanceChange: /* @__PURE__ */ print(onBalanceChangeDocument),
  onBonusBalanceChange: /* @__PURE__ */ print(onBonusBalanceChangeDocument),
  onBonusGameRoundEvent: /* @__PURE__ */ print(onBonusGameRoundEventDocument),
  onCalculatedCashback: /* @__PURE__ */ print(onCalculatedCashbackDocument),
  onDeposit: /* @__PURE__ */ print(onDepositDocument),
  onFfsBetCountLimit: /* @__PURE__ */ print(onFfsBetCountLimitDocument),
  onFfsBetLimit: /* @__PURE__ */ print(onFfsBetLimitDocument),
  onFreeSpinResult: /* @__PURE__ */ print(onFreeSpinResultDocument),
  onFrontNotification: /* @__PURE__ */ print(onFrontNotificationDocument),
  onLoyaltyCustomerLevel: /* @__PURE__ */ print(onLoyaltyCustomerLevelDocument),
  onRefusedCashback: /* @__PURE__ */ print(onRefusedCashbackDocument),
  onTicketChatEvent: /* @__PURE__ */ print(onTicketChatEventDocument),
  onUpdatedCashback: /* @__PURE__ */ print(onUpdatedCashbackDocument),
  onVipFastTrackStateChangeEvent: /* @__PURE__ */ print(onVipFastTrackStateChangeEventDocument),
  onWagerCreated: /* @__PURE__ */ print(onWagerCreatedDocument),
  onWagerResult: /* @__PURE__ */ print(onWagerResultDocument),
  onWagerUpdated: /* @__PURE__ */ print(onWagerUpdatedDocument)
};
var WebSocketAccessRole = /* @__PURE__ */ ((WebSocketAccessRole2) => {
  WebSocketAccessRole2[WebSocketAccessRole2["ALL"] = 0] = "ALL";
  WebSocketAccessRole2[WebSocketAccessRole2["ANONYMOUS"] = 1] = "ANONYMOUS";
  WebSocketAccessRole2[WebSocketAccessRole2["AUTHORIZED"] = 2] = "AUTHORIZED";
  return WebSocketAccessRole2;
})(WebSocketAccessRole || {});
function isWSSupported() {
  return !!process.env.VUE_APP_RENDERING_CSR && "WebSocket" in window && window.WebSocket.CLOSING === 2;
}
function isSubscriptionAllowed(isLoggedIn, access) {
  return isLoggedIn && access !== WebSocketAccessRole.ANONYMOUS || !isLoggedIn && access !== WebSocketAccessRole.AUTHORIZED;
}
class WebSocketSubscription {
  constructor({
    method,
    onMessage,
    variables,
    polling,
    access,
    isWsEnabled
  }) {
    this.pollingInterval = 0;
    this.method = method;
    this.query = webSocketDocuments[method];
    this.variables = variables;
    this.onMessage = onMessage;
    this.polling = polling;
    this.access = access;
    this.isWsEnabled = isWsEnabled;
  }
  setPollingTimeout(timeout) {
    if (this.polling) {
      this.polling.timeout = timeout;
      if (this.pollingInterval) {
        this.startPollingRequestInternal();
      }
    }
  }
  setIsWsEnabled(isWsEnabled) {
    this.isWsEnabled = isWsEnabled;
  }
  isAllowed({ isLoggedIn }) {
    return isSubscriptionAllowed(isLoggedIn, this.access);
  }
  isWebSocketsEnabled() {
    return !!this.isWsEnabled;
  }
  isPollingMustBeCalledOnLogin() {
    var _a;
    return !!((_a = this.polling) == null ? void 0 : _a.callOnLogin);
  }
  getWsSubscriptionInput() {
    return {
      id: this.method,
      query: this.query,
      variables: this.variables,
      onMessage: this.onMessage,
      onError: (error) => {
        logger.error(`[WebSocket] Failed to subscribe to event: ${this.method}. Fallback to polling.`, error);
        this.startPollingRequest();
      }
    };
  }
  startPollingRequest() {
    if (this.pollingInterval) {
      return;
    }
    this.startPollingRequestInternal();
  }
  startPollingRequestInternal() {
    if (this.polling) {
      this.stopPollingRequest();
      this.pollingInterval = Timer.setTimeout(() => {
        assert(this.polling);
        Promise.resolve(this.polling.callback()).then(() => this.startPollingRequestInternal());
      }, this.polling.timeout);
    }
  }
  callPollingRequest() {
    if (this.polling) {
      this.polling.callback();
      if (this.pollingInterval) {
        this.startPollingRequestInternal();
      }
    }
  }
  stopPollingRequest() {
    if (this.pollingInterval) {
      Timer.clearTimeout(this.pollingInterval);
      this.pollingInterval = 0;
    }
  }
}
var WebSocketMessageType = /* @__PURE__ */ ((WebSocketMessageType2) => {
  WebSocketMessageType2["GQL_CONNECTION_INIT"] = "connection_init";
  WebSocketMessageType2["GQL_CONNECTION_TERMINATE"] = "connection_terminate";
  WebSocketMessageType2["GQL_START"] = "start";
  WebSocketMessageType2["GQL_STOP"] = "stop";
  WebSocketMessageType2["GQL_CONNECTION_ACK"] = "connection_ack";
  WebSocketMessageType2["GQL_CONNECTION_ERROR"] = "connection_error";
  WebSocketMessageType2["GQL_CONNECTION_KEEP_ALIVE"] = "ka";
  WebSocketMessageType2["GQL_DATA"] = "data";
  WebSocketMessageType2["GQL_ERROR"] = "error";
  WebSocketMessageType2["GQL_COMPLETE"] = "complete";
  WebSocketMessageType2["GQL_RECONNECT"] = "reconnect";
  return WebSocketMessageType2;
})(WebSocketMessageType || {});
const WebSocketMessageTypes = /* @__PURE__ */ Object.values(WebSocketMessageType);
function isWebSocketMessageType(type) {
  return WebSocketMessageTypes.includes(type);
}
function isWebSocketData(value) {
  return isObject(value) && isWebSocketMessageType(value.type);
}
class WebSocketHolder {
  constructor(options) {
    this.socket = null;
    this.subscriptions = {};
    this.isConnectionAck = false;
    this.keepAliveDate = 0;
    this.keepAliveInterval = 0;
    this.serverKeepAliveTimeout = 2e4;
    this.sessionExpiresAt = 0;
    this.isReconnected = false;
    this.isDisconnected = false;
    this.openSocketTimeout = 1e4;
    this.checkOpenedConnectionTimer = 0;
    this.host = getLocationHost();
    this.isHttps = isHttps();
    this.options = options;
    this.connect();
  }
  connect() {
    if (!isWSSupported()) {
      return;
    }
    this.initConnection();
  }
  initConnection() {
    if (!this.socket) {
      try {
        const wsUrl = `ws${this.isHttps ? "s" : ""}://${this.host}${process.env.VUE_APP_WEB_SOCKET_PUBLIC_URL}`;
        if (process.env.VUE_APP_PLATFORM_CORDOVA) {
          const options = { timeout: this.openSocketTimeout };
          this.socket = new CordovaWebSocket(wsUrl, options);
        } else {
          this.socket = new WebSocket(wsUrl);
        }
        this.checkOpenedConnectionTimer = Timer.setTimeout(() => {
          assert(this.socket);
          if (this.socket.readyState === WebSocket.CONNECTING) {
            this.reconnect();
          }
          this.checkOpenedConnectionTimer = 0;
        }, this.openSocketTimeout);
        const { socket } = this;
        assert(socket);
        socket.addEventListener("open", () => {
          this.sendEvent({
            type: WebSocketMessageType.GQL_CONNECTION_INIT,
            payload: {}
          });
        });
        socket.addEventListener("message", this.onSocketMessage.bind(this));
        socket.addEventListener("close", (event) => {
          if (event.code !== 1e3) {
            logger.warn(`[WebSocket] connection closed: ${JSON.stringify(event)}`);
            this.reconnect();
          }
        });
        socket.addEventListener("error", (event) => {
          logger.warn(`[WebSocket] connection error: ${JSON.stringify(event)}`);
          this.isReconnected = false;
          this.isConnectionAck = false;
          this.reconnect();
        });
      } catch (error) {
        logger.error("[WebSocket] connection error", error);
        this.reconnect();
      }
    }
  }
  subscribe({
    id,
    query,
    variables,
    onMessage,
    onError,
    onComplete
  }) {
    if (!isWSSupported()) {
      return;
    }
    if (this.subscriptions[id]) {
      return;
    }
    const subscription = {
      id,
      isSubscribed: false,
      query,
      variables,
      onMessage,
      onError,
      onComplete
    };
    this.subscriptions[id] = subscription;
    this.applySubscription(subscription);
  }
  unsubscribe({ id }) {
    if (this.subscriptions[id]) {
      if (this.subscriptions[id].isSubscribed) {
        this.sendEvent({
          id,
          type: WebSocketMessageType.GQL_STOP
        });
      }
      delete this.subscriptions[id];
    }
  }
  async disconnect() {
    var _a;
    if (this.isDisconnected) {
      return;
    }
    if (this.socket) {
      if (this.isConnectionAck) {
        for (const subscription of Object.values(this.subscriptions)) {
          assert(subscription);
          if (subscription.isSubscribed) {
            this.sendEvent({
              id: subscription.id,
              type: WebSocketMessageType.GQL_STOP
            });
            subscription.isSubscribed = false;
          }
        }
        this.sendEvent({
          type: WebSocketMessageType.GQL_CONNECTION_TERMINATE
        });
      }
      (_a = this.socket) == null ? void 0 : _a.close();
      await this.waitSocketClose();
      this.socket = null;
    }
    this.clearCheckOpenedConnectionTimer();
    this.stopKeepAliveCheck();
    this.keepAliveDate = 0;
    this.sessionExpiresAt = 0;
    this.options.onDisconnect();
    this.isDisconnected = true;
  }
  clearCheckOpenedConnectionTimer() {
    if (this.checkOpenedConnectionTimer) {
      Timer.clearTimeout(this.checkOpenedConnectionTimer);
      this.checkOpenedConnectionTimer = 0;
    }
  }
  sendEvent(data) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(data));
      if (data.type === WebSocketMessageType.GQL_CONNECTION_TERMINATE) {
        this.socket.close();
        this.socket = null;
      }
    }
  }
  waitSocketClose() {
    return new Promise((resolve) => {
      const isConnectionClosed = () => !this.socket || this.socket.readyState === WebSocket.CLOSED;
      if (isConnectionClosed()) {
        resolve();
        return;
      }
      const waitIsConnectionClosed = () => {
        Timer.setTimeout(() => {
          if (isConnectionClosed()) {
            resolve();
          } else {
            waitIsConnectionClosed();
          }
        }, 5);
      };
      waitIsConnectionClosed();
    });
  }
  onSocketMessage(event) {
    const data = JSON.parse(event.data);
    assert(isWebSocketData(data), "Unexpected socket message data");
    switch (data.type) {
      case WebSocketMessageType.GQL_CONNECTION_ACK:
        this.onConnectionAck(data);
        break;
      case WebSocketMessageType.GQL_CONNECTION_KEEP_ALIVE:
        this.onKeepAlive();
        break;
      case WebSocketMessageType.GQL_DATA:
        this.onMessageData(data);
        break;
      case WebSocketMessageType.GQL_ERROR:
        this.onMessageError(data);
        break;
      case WebSocketMessageType.GQL_COMPLETE:
        this.onMessageComplete(data);
        break;
      case WebSocketMessageType.GQL_CONNECTION_ERROR:
        this.onServerConnectionError(data);
        break;
      case WebSocketMessageType.GQL_RECONNECT:
        void this.reconnect();
        break;
    }
  }
  startKeepAliveCheck() {
    this.stopKeepAliveCheck();
    this.keepAliveInterval = Timer.setInterval(this.pingKeepAlive.bind(this), 1e3);
  }
  stopKeepAliveCheck() {
    if (this.keepAliveInterval) {
      Timer.clearInterval(this.keepAliveInterval);
      this.keepAliveInterval = 0;
    }
  }
  pingKeepAlive() {
    const serverKANotReceived = this.keepAliveDate > 0 && this.keepAliveDate < Date.now() - this.serverKeepAliveTimeout;
    const clientKAExpired = Date.now() > this.sessionExpiresAt;
    if (serverKANotReceived || clientKAExpired) {
      this.reconnect();
    }
  }
  onKeepAlive() {
    this.keepAliveDate = Date.now();
  }
  onConnectionAck(data) {
    this.sessionExpiresAt = new Date(data.payload.expiresAt).getTime();
    this.isConnectionAck = true;
    this.clearCheckOpenedConnectionTimer();
    for (const subscription of Object.values(this.subscriptions)) {
      this.applySubscription(subscription);
    }
    this.startKeepAliveCheck();
    this.options.onConnectionAck();
  }
  onMessageData(data) {
    if (this.subscriptions[data.id]) {
      const subscription = this.subscriptions[data.id];
      assert(subscription);
      if (subscription.onMessage) {
        subscription.onMessage(data.payload.data);
      }
    }
  }
  onMessageError(data) {
    if (this.subscriptions[data.id]) {
      const subscription = this.subscriptions[data.id];
      assert(subscription);
      if (subscription.onError) {
        subscription.onError(new Error(data.payload.message));
      }
    }
  }
  onMessageComplete(data) {
    if (this.subscriptions[data.id]) {
      const subscription = this.subscriptions[data.id];
      assert(subscription);
      if (subscription.onComplete) {
        subscription.onComplete();
      }
    }
  }
  onServerConnectionError(data) {
    logger.error("[WebSocket] server connection error", data.payload);
    this.reconnect();
  }
  applySubscription(subscription) {
    if (this.isConnectionAck) {
      assert(this.socket);
      subscription.isSubscribed = true;
      this.sendEvent({
        id: subscription.id,
        type: WebSocketMessageType.GQL_START,
        payload: {
          query: subscription.query,
          variables: subscription.variables
        }
      });
    }
  }
  async reconnect() {
    if (this.isReconnected) {
      return;
    }
    if (!this.isConnectionAck) {
      await this.disconnect();
    }
    this.options.onReconnect();
    this.isReconnected = true;
  }
  isAckReceived() {
    return this.isConnectionAck;
  }
}
class GraphQLWebSocketService {
  constructor() {
    this.isEnabled = isWSSupported();
    this.socket = null;
    this.subscriptions = {};
    this.sockets = [];
    this.connectionDate = 0;
    this.reconnectTimeout = 5e3;
    this.connectionTimer = 0;
    window.addEventListener("beforeunload", () => {
      void this.disconnect();
      this.checkConnections();
    });
    onWindowVisibilityChanged((isVisible) => {
      if (isVisible) {
        void this.callPollingRequests();
        void this.reconnectInternal(false);
      }
    }).addVisibilityChangeEventListener();
  }
  isSupported() {
    return this.isEnabled;
  }
  async initConnection(reconnect = false, force = false) {
    if (!this.hasWsEnabledSubscription()) {
      return;
    }
    if (this.connectionTimer) {
      Timer.clearTimeout(this.connectionTimer);
      this.connectionTimer = 0;
      return;
    }
    const diffDate = Date.now() - this.connectionDate;
    if (force || diffDate > this.reconnectTimeout) {
      await this.initSocket(reconnect);
    } else {
      this.connectionTimer = Timer.setTimeout(() => {
        void this.initSocket(reconnect);
        this.connectionTimer = 0;
      }, this.reconnectTimeout - diffDate);
    }
  }
  initSocket(reconnect = false) {
    return new Promise((resolve) => {
      if (!this.socket || reconnect) {
        for (const socket of this.sockets) {
          if (!socket.isAckReceived()) {
            void socket.disconnect();
          }
        }
        this.connectionDate = Date.now();
        const newSocket = new WebSocketHolder({
          onError: () => {
            this.checkConnections();
          },
          onReconnect: () => {
            void this.reconnectInternal(false);
          },
          onConnectionAck: async () => {
            var _a;
            await ((_a = this.socket) == null ? void 0 : _a.disconnect());
            this.socket = newSocket;
            assert(newSocket);
            for (const subscription of Object.values(this.subscriptions)) {
              if (subscription.isWebSocketsEnabled()) {
                newSocket.subscribe(subscription.getWsSubscriptionInput());
              }
            }
            this.checkConnections();
            resolve();
          },
          onDisconnect: () => {
            this.sockets = this.sockets.filter((socket) => socket !== newSocket);
            this.checkConnections();
            resolve();
          }
        });
        this.sockets.push(newSocket);
      } else {
        resolve();
      }
    });
  }
  checkConnections() {
    if (this.sockets.some((socket) => socket.isAckReceived())) {
      this.stopAllPollingRequests();
    } else {
      this.startAllPollingRequests();
    }
  }
  connect() {
    return this.initConnection(true, true);
  }
  setEnabled(isEnabled) {
    this.isEnabled = isEnabled;
    if (!this.isEnabled) {
      void this.disconnect();
      for (const subscription of Object.values(this.subscriptions)) {
        subscription.startPollingRequest();
      }
    } else {
      for (const subscription of Object.values(this.subscriptions)) {
        this.initSubscription(subscription);
      }
    }
  }
  subscribe(subscription) {
    var _a;
    if (this.isSubscribed(subscription)) {
      logger.warn(`[WebSocket] Already subscribed to event ${subscription.method}. Please unsubscribe first.`);
      return;
    }
    this.subscriptions[subscription.method] = subscription;
    this.initSubscription(subscription);
    if ((_a = this.socket) == null ? void 0 : _a.isAckReceived()) {
      subscription.stopPollingRequest();
    }
  }
  initSubscription(subscription) {
    if (this.isSupported() && subscription.isWebSocketsEnabled()) {
      if (!this.sockets.length) {
        void this.connect();
      }
      for (const socket of this.sockets) {
        socket.subscribe(subscription.getWsSubscriptionInput());
      }
    } else {
      subscription.startPollingRequest();
    }
  }
  hasWsEnabledSubscription() {
    return !!Object.values(this.subscriptions).some((subscription) => subscription.isWebSocketsEnabled());
  }
  unsubscribe(subscription) {
    for (const socket of this.sockets) {
      socket.unsubscribe({ id: subscription.method });
    }
    subscription.stopPollingRequest();
    if (this.subscriptions[subscription.method]) {
      delete this.subscriptions[subscription.method];
    }
    if (!this.hasWsEnabledSubscription()) {
      void this.disconnect();
    }
  }
  async disconnect() {
    if (this.connectionTimer) {
      Timer.clearTimeout(this.connectionTimer);
      this.connectionTimer = 0;
    }
    await Promise.all(
      this.sockets.map((socket) => socket.disconnect())
    );
  }
  reconnectInternal(force) {
    return this.initConnection(true, force);
  }
  reconnect() {
    return this.reconnectInternal(true);
  }
  isSubscribed(subscription) {
    return !!this.subscriptions[subscription.method];
  }
  stopAllPollingRequests() {
    for (const subscription of Object.values(this.subscriptions)) {
      if (subscription.isWebSocketsEnabled()) {
        subscription.stopPollingRequest();
      }
    }
  }
  startAllPollingRequests() {
    for (const subscription of Object.values(this.subscriptions)) {
      subscription.startPollingRequest();
    }
  }
  callPollingRequests() {
    for (const subscription of Object.values(this.subscriptions)) {
      subscription.callPollingRequest();
    }
  }
}
function isSocketOpen(socket) {
  return socket.readyState === WebSocket.OPEN;
}
class SocketWrapper {
  constructor(options) {
    this.options = options;
    this.socket = null;
    this.socketCounter = 0;
    this.sendCounter = 0;
    this.perSocketMap = /* @__PURE__ */ new WeakMap();
  }
  createSocket() {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      const options = { timeout: this.options.openTimeout };
      return new CordovaWebSocket(this.options.url, options);
    }
    return new WebSocket(this.options.url);
  }
  getSocketData(socket) {
    let socketData = this.perSocketMap.get(socket);
    if (!socketData) {
      this.socketCounter += 1;
      socketData = {
        id: this.socketCounter,
        deferred: new Deferred(),
        timeout: 0
      };
      this.perSocketMap.set(socket, socketData);
    }
    return socketData;
  }
  async connect() {
    if (this.socket) {
      return this.getSocketData(this.socket).deferred.promise;
    }
    const socket = this.createSocket();
    const { deferred } = this.getSocketData(socket);
    socket.addEventListener("open", () => {
      deferred.resolve(socket);
    });
    socket.addEventListener("error", (event) => {
      console.warn("Socket error event occurred", event);
      deferred.reject(new Error("Unexpected socket error"));
      void this.detach("WebSocket received an error event");
    });
    socket.addEventListener("close", () => {
      if (this.socket !== socket) {
        return;
      }
      void this.detach();
    });
    this.socket = socket;
    void deferred.promise.then(() => {
      this.refreshTimeout();
    });
    return deferred.promise;
  }
  refreshTimeout() {
    const { socket } = this;
    assert(socket, "Expected socket to be defined");
    if (socket) {
      this.resetTimeout(socket, () => {
        void this.detach();
      });
    }
  }
  resetTimeout(socket, callback) {
    const socketDate = this.getSocketData(socket);
    Timer.clearTimeout(socketDate.timeout);
    if (callback) {
      socketDate.timeout = Timer.setTimeout(callback, this.options.openTimeout);
    }
  }
  async send(data) {
    this.sendCounter += 1;
    const socket = await this.connect();
    socket.send(JSON.stringify(data));
    this.refreshTimeout();
  }
  async detach(reason) {
    return new Promise((resolve, reject) => {
      const { socket } = this;
      if (socket) {
        const { deferred, timeout } = this.getSocketData(socket);
        this.socket = null;
        Timer.clearTimeout(timeout);
        const { readyState } = socket;
        if (readyState !== WebSocket.CLOSED && readyState !== WebSocket.CLOSING) {
          socket.close();
        }
        if (reason) {
          deferred.promise.catch(reject);
          deferred.reject(new Error(reason));
        }
      }
      resolve();
    });
  }
  isSocketOpen() {
    return !!(this.socket && isSocketOpen(this.socket));
  }
}
class SocketEmitter {
  constructor(socketWrapper) {
    this.socketWrapper = socketWrapper;
  }
  async emit(type, payload) {
    const data = {
      type,
      payload
    };
    await this.socketWrapper.send(data);
  }
}
export {
  AccountType,
  AchievementEventType,
  BetEventType,
  BetOperationType,
  BetType,
  BonusAmountFormula,
  BonusCloseReason,
  BonusRequirementChangeType,
  BonusType,
  ButtonAction,
  CampaignType,
  CreatedBonusType,
  DepositOperationType,
  DepositStatus,
  DisplayType,
  FreeBetResultType,
  FreeBetTransferType,
  FreeBetType,
  FreeSpinResultType,
  FreeSpinType,
  FrontNotificationType,
  GraphQLWebSocketService,
  IdentificationLevel,
  InternalSportBonusType,
  LoyaltyCustomerLevelType,
  OfferStatus,
  OrderStatus,
  ProgramRequirementType,
  ProgramRewardType,
  RefProgramNotificationType,
  RewardType,
  SocketEmitter,
  SocketWrapper,
  SportBonusActivatedType,
  SportBonusCloseReason,
  SportBonusConfirmationType,
  SportBonusOperationType,
  SportBonusRequirementChangeOperationType,
  SportBonusRequirementType,
  SportBonusWagerResultType,
  TicketChatEventType,
  VerificationStatus,
  VideoVerificationStatus,
  VipFastTrackStatus,
  WagerResultType,
  WalletType,
  WebSocketAccessRole,
  WebSocketSubscription,
  getWsSdk,
  isSubscriptionAllowed,
  isWSSupported,
  onAchievementEventDocument,
  onBalanceChangeDocument,
  onBonusBalanceChangeDocument,
  onBonusGameRoundEventDocument,
  onCalculatedCashbackDocument,
  onDepositDocument,
  onFfsBetCountLimitDocument,
  onFfsBetLimitDocument,
  onFreeSpinResultDocument,
  onFrontNotificationDocument,
  onLoyaltyCustomerLevelDocument,
  onRefusedCashbackDocument,
  onTicketChatEventDocument,
  onUpdatedCashbackDocument,
  onVipFastTrackStateChangeEventDocument,
  onWagerCreatedDocument,
  onWagerResultDocument,
  onWagerUpdatedDocument,
  webSocketDocuments
};
