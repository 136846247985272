import { onComponentActivated, onComponentDeactivated } from "@leon-hub/vue-utils";
import { watch, onMounted, onActivated, onBeforeUnmount, onDeactivated, ref } from "vue";
import { getViewportDimension, getClientDimension, getIsPortraitOrientation } from "@leon-hub/utils";
import { useDebounce } from "@leon-hub/debounce";
function onWindowVisibilityChanged(callback) {
  function visibleCallback() {
    void callback(true);
  }
  function hiddenCallback() {
    void callback(false);
  }
  function resizeCallback() {
    void callback(document.visibilityState === "visible");
  }
  function addVisibilityChangeEventListener() {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      window.document.addEventListener("resume", visibleCallback);
      window.document.addEventListener("pause", hiddenCallback);
    } else {
      window.document.addEventListener("visibilitychange", resizeCallback);
    }
  }
  function removeVisibilityChangeEventListener() {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      window.document.removeEventListener("resume", visibleCallback);
      window.document.removeEventListener("pause", hiddenCallback);
    } else {
      window.document.removeEventListener("visibilitychange", resizeCallback);
    }
  }
  return {
    addVisibilityChangeEventListener,
    removeVisibilityChangeEventListener
  };
}
function useWindowVisibilityChanged(callback) {
  let hasActiveListener = false;
  const {
    addVisibilityChangeEventListener,
    removeVisibilityChangeEventListener
  } = onWindowVisibilityChanged(callback);
  function addEventListener() {
    if (!hasActiveListener) {
      addVisibilityChangeEventListener();
      hasActiveListener = true;
    }
  }
  function removeEventListener() {
    if (hasActiveListener) {
      removeVisibilityChangeEventListener();
      hasActiveListener = false;
    }
  }
  onComponentActivated(addEventListener);
  onComponentDeactivated(removeEventListener);
}
var WindowSize = /* @__PURE__ */ ((WindowSize2) => {
  WindowSize2["PHONE"] = "p";
  WindowSize2["DESKTOP_SMALL"] = "ds";
  WindowSize2["DESKTOP_MEDIUM"] = "dm";
  WindowSize2["DESKTOP_LARGE"] = "dl";
  return WindowSize2;
})(WindowSize || {});
function useWindowResize(callback, options = {}) {
  let hasActiveListener = false;
  let dimensions = getViewportDimension();
  function resizeCallbackInner() {
    const newDimensions = getViewportDimension();
    void callback({
      deltaX: dimensions.viewportWidth - newDimensions.viewportWidth,
      deltaY: dimensions.viewportHeight - newDimensions.viewportHeight,
      viewportHeight: newDimensions.viewportHeight,
      viewportWidth: newDimensions.viewportWidth
    });
    dimensions = newDimensions;
  }
  const resizeCallback = options.debounce ? useDebounce(resizeCallbackInner) : resizeCallbackInner;
  function addEventListener() {
    if (!hasActiveListener) {
      dimensions = getViewportDimension();
      window.addEventListener("resize", resizeCallback);
      hasActiveListener = true;
    }
  }
  function removeEventListener() {
    if (hasActiveListener) {
      window.removeEventListener("resize", resizeCallback);
      hasActiveListener = false;
    }
  }
  if (options.condition) {
    watch(options.condition, (newValue) => {
      if (newValue) {
        addEventListener();
      } else {
        removeEventListener();
      }
    }, { immediate: true });
  } else {
    onMounted(addEventListener);
    onActivated(addEventListener);
  }
  onBeforeUnmount(removeEventListener);
  onDeactivated(removeEventListener);
}
function getBreakpoints() {
  return {
    [WindowSize.PHONE]: [0, 768],
    [WindowSize.DESKTOP_SMALL]: [768, 1024],
    [WindowSize.DESKTOP_MEDIUM]: [1024, 1280],
    [WindowSize.DESKTOP_LARGE]: [1280, Infinity]
  };
}
function useCurrentWindowSize() {
  const breakpoints = getBreakpoints();
  const windowSize = ref(WindowSize.PHONE);
  function calculateWindowSize(viewportWidth) {
    for (const [breakpoint, values] of Object.entries(breakpoints)) {
      if (viewportWidth >= values[0] && viewportWidth < values[1]) {
        windowSize.value = breakpoint;
        break;
      }
    }
  }
  onComponentActivated(() => {
    calculateWindowSize(getViewportDimension().viewportWidth);
  });
  useWindowResize(({ viewportWidth }) => {
    calculateWindowSize(viewportWidth);
  });
  return {
    windowSize
  };
}
function useCustomMessageListener(callback) {
  let isSubscribed = false;
  function subscribe() {
    if (!isSubscribed) {
      window.addEventListener("message", callback);
      isSubscribed = true;
    }
  }
  function unsubscribe() {
    window.removeEventListener("message", callback);
    isSubscribed = false;
  }
  if (!process.env.VUE_APP_PRERENDER) {
    onMounted(subscribe);
    onActivated(subscribe);
    onBeforeUnmount(unsubscribe);
    onDeactivated(unsubscribe);
  }
}
function useDocumentDimensionSafeSubscribe({
  debounce,
  onWindowResize
} = { debounce: 100 }) {
  const clientDimension = ref({
    clientHeight: 0,
    clientWidth: 0
  });
  const isPortraitOrientation = ref(false);
  function recalculate() {
    clientDimension.value = getClientDimension();
    isPortraitOrientation.value = getIsPortraitOrientation();
    onWindowResize == null ? void 0 : onWindowResize();
  }
  useWindowResize(recalculate, { debounce });
  recalculate();
  return {
    clientDimension,
    isPortraitOrientation
  };
}
function useWindowEvent(event, callback) {
  let hasActiveListener = false;
  function addEventListener() {
    if (!hasActiveListener) {
      window.addEventListener(event, callback);
      hasActiveListener = true;
    }
  }
  function removeEventListener() {
    if (hasActiveListener) {
      window.removeEventListener(event, callback);
      hasActiveListener = false;
    }
  }
  onComponentActivated(addEventListener);
  onComponentDeactivated(removeEventListener);
}
export {
  onWindowVisibilityChanged,
  useCurrentWindowSize,
  useCustomMessageListener,
  useDocumentDimensionSafeSubscribe,
  useWindowEvent,
  useWindowResize,
  useWindowVisibilityChanged
};
