import { isString, isValidObject, isOptionalString, isUndefined, isArrayOf, isObjectValues, isNumber, isBoolean, isOptionalBoolean, isOptionalNumber, isNull, isObject } from "@leon-hub/guards";
import { isOptionalAnyIconName, isIconSize } from "@leon-hub/icons";
import { Json } from "@leon-hub/utils";
import { logger } from "@leon-hub/logging";
var NavigationGroup = /* @__PURE__ */ ((NavigationGroup2) => {
  NavigationGroup2["TOP_NAVIGATION"] = "TOP_NAVIGATION";
  NavigationGroup2["TOP_SHOWCASE_NAVIGATION"] = "TOP_SHOWCASE_NAVIGATION";
  NavigationGroup2["TOP_GAMES_NAVIGATION"] = "TOP_GAMES_NAVIGATION";
  NavigationGroup2["BOTTOM_NAVIGATION"] = "BOTTOM_NAVIGATION";
  NavigationGroup2["BOTTOM_NAVIGATION_SHORT"] = "BOTTOM_NAVIGATION_SHORT";
  NavigationGroup2["SIDEBAR_LEFT_NAVIGATION"] = "SIDEBAR_LEFT_NAVIGATION";
  NavigationGroup2["SIDEBAR_LEFT_TAB_NAVIGATION"] = "SIDEBAR_LEFT_TAB_NAVIGATION";
  NavigationGroup2["SIDEBAR_RIGHT_NAVIGATION"] = "SIDEBAR_RIGHT_NAVIGATION";
  NavigationGroup2["PROFILE_MENU_NAVIGATION"] = "PROFILE_MENU_NAVIGATION";
  NavigationGroup2["PROFILE_NAVIGATION"] = "PROFILE_NAVIGATION";
  NavigationGroup2["GENERIC_INFO_NAVIGATION"] = "GENERIC_INFO_NAVIGATION";
  NavigationGroup2["BOOTSTRAP_NAVIGATION"] = "BOOTSTRAP_NAVIGATION";
  NavigationGroup2["SETTINGS_NAVIGATION"] = "SETTINGS_NAVIGATION";
  NavigationGroup2["AFFILIATE_PROGRAM_NAVIGATION"] = "AFFILIATE_PROGRAM_NAVIGATION";
  NavigationGroup2["AFFILIATE_PROGRAM_FOOTER_NAVIGATION"] = "AFFILIATE_PROGRAM_FOOTER_NAVIGATION";
  NavigationGroup2["PROFILE_SETTINGS_NAVIGATION"] = "PROFILE_SETTINGS_NAVIGATION";
  NavigationGroup2["TELEGRAM_BOT_NAVIGATION"] = "TELEGRAM_BOT_NAVIGATION";
  NavigationGroup2["ERROR_404_NAVIGATION"] = "ERROR_404_NAVIGATION";
  NavigationGroup2["PROFILE_SIDEBAR_MENU"] = "PROFILE_SIDEBAR_MENU";
  NavigationGroup2["RESPONSIBLE_GAMBLING_NAVIGATION"] = "RESPONSIBLE_GAMBLING_NAVIGATION";
  NavigationGroup2["SIDEBAR_LEFT_TOP_NAVIGATION"] = "SIDEBAR_LEFT_TOP_NAVIGATION";
  NavigationGroup2["SIDEBAR_LEFT_MIDDLE_NAVIGATION"] = "SIDEBAR_LEFT_MIDDLE_NAVIGATION";
  NavigationGroup2["DEVTOOLS_NAVIGATION"] = "DEVTOOLS_NAVIGATION";
  NavigationGroup2["PROMOTIONS_NAVIGATION"] = "PROMOTIONS_NAVIGATION";
  NavigationGroup2["PROMOTION_CATEGORIES_NAVIGATION"] = "PROMOTION_CATEGORIES_NAVIGATION";
  NavigationGroup2["PROMOTIONS_SINGLE_CATEGORY_NAVIGATION"] = "PROMOTIONS_SINGLE_CATEGORY_NAVIGATION";
  NavigationGroup2["PROMOTION_SINGLE_CATEGORY_CATEGORIES_NAVIGATION"] = "PROMOTION_SINGLE_CATEGORY_CATEGORIES_NAVIGATION";
  NavigationGroup2["TOURNAMENTS_SINGLE_CATEGORY_NAVIGATION"] = "TOURNAMENTS_SINGLE_CATEGORY_NAVIGATION";
  NavigationGroup2["TOURNAMENTS_SINGLE_CATEGORY_CATEGORIES_NAVIGATION"] = "TOURNAMENTS_SINGLE_CATEGORY_CATEGORIES_NAVIGATION";
  return NavigationGroup2;
})(NavigationGroup || {});
const navigationGroups = /* @__PURE__ */ Object.freeze(
  /* @__PURE__ */ Object.values(NavigationGroup)
);
function isNavigationGroup(value) {
  return navigationGroups.includes(value);
}
function isNavigationItemIcon(value) {
  return isString(value) || isValidObject(value, {
    name: [isOptionalAnyIconName],
    size: [isIconSize]
  });
}
function isNavigationItemCdnIcon(value) {
  return isString(value) || isValidObject(value, {
    src: [isString],
    width: [isUndefined, isString],
    height: [isUndefined, isString]
  });
}
function isNavigationItem(value) {
  return isValidObject(value, {
    id: [isString],
    routeName: [isOptionalString],
    url: [isOptionalString],
    caption: [isOptionalString],
    label: [isOptionalString],
    icon: [isUndefined, isNavigationItemIcon],
    iconSrc: [isOptionalString],
    iconCdn: [isUndefined, isNavigationItemCdnIcon],
    children: [isUndefined, isArrayOf.bind(null, isNavigationItem)],
    props: [isUndefined, isObjectValues.bind(null, [isNumber, isString, isBoolean])],
    asModal: [isOptionalBoolean],
    isActive: [isOptionalBoolean],
    badge: [isOptionalString],
    progress: [isOptionalNumber],
    suffixText: [isOptionalString],
    parentId: [isNull, isOptionalString],
    type: [isOptionalString]
  });
}
function isNavigationConfig(value) {
  return isObject(value) && Object.keys(value).every((key) => {
    if (!isNavigationGroup(key)) {
      console.error(`Navigation group ${key} is not declared in enum NavigationGroup`);
    }
    const navigationItems = value[key];
    if (Array.isArray(navigationItems)) {
      for (const item of navigationItems) {
        if (!isNavigationItem(item)) {
          console.error(`Invalid navigation item: group: ${key}; item=${Json.stringify(item)}`);
        }
      }
    } else {
      console.error(`Navigation items are not an array=${Json.stringify(navigationItems)}`);
    }
    return true;
  });
}
function isNavigationItemActive(navigationItem, routeName) {
  var _a, _b;
  if (navigationItem.isActive !== void 0) {
    return navigationItem.isActive;
  }
  if (routeName === navigationItem.routeName) {
    return true;
  }
  if (routeName && navigationItem.navigatedFrom) {
    return (_a = navigationItem.navigatedFrom) == null ? void 0 : _a.includes(String(routeName));
  }
  if ((_b = navigationItem.children) == null ? void 0 : _b.length) {
    return navigationItem.children.some((navItemChild) => isNavigationItemActive(navItemChild, routeName));
  }
  return false;
}
const setNavigationBreadcrumbs = (navigationItems, routeName) => navigationItems.map((navigationItem) => {
  var _a;
  const isActive = isNavigationItemActive(navigationItem, routeName);
  if (isActive) {
    let children;
    if ((_a = navigationItem.children) == null ? void 0 : _a.length) {
      children = setNavigationBreadcrumbs(navigationItem.children, routeName);
    }
    return { ...navigationItem, isActive, children };
  }
  return { ...navigationItem };
});
function getNavigationItemRouteLocation(navigationItem, position) {
  const { routeName, props } = navigationItem;
  let routerParameters;
  if (props == null ? void 0 : props.routeParams) {
    try {
      routerParameters = JSON.parse(`${props.routeParams}`);
    } catch (error) {
      logger.error(`getNavigationItemRouteLocation at ${position}: wrong routeParams: ${props.routeParams}`, error);
    }
  }
  return { name: routeName, params: routerParameters };
}
function getNavigationItemIconLocation(iconSource, imageCdnUrl, theme) {
  return /^https?/.test(iconSource) ? iconSource : `${imageCdnUrl}${iconSource.replace(/^\//, "").replace("{theme}", theme.toLowerCase())}`;
}
export {
  NavigationGroup,
  getNavigationItemIconLocation,
  getNavigationItemRouteLocation,
  isNavigationConfig,
  isNavigationItem,
  isNavigationItemActive,
  isNavigationItemIcon,
  setNavigationBreadcrumbs
};
