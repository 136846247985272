import type { ToRefs } from 'vue';
import {
  computed,
  toRef,
} from 'vue';

import type { SiteConfigDocument } from 'web/src/modules/core/store/types';
import useSiteConfigStore from 'web/src/modules/core/store/useSiteConfigStore';

export type UseWebSocketsConfig = ToRefs<SiteConfigDocument['webSockets']>;

export default function useWebSocketsConfig(): UseWebSocketsConfig {
  const siteConfigStore = useSiteConfigStore();
  const webSockets = toRef(siteConfigStore, 'webSockets');

  return {
    isEnabled: computed(() => !!webSockets.value?.isEnabled),
    isBalanceEnabled: computed(() => !!webSockets.value?.isBalanceEnabled),
    isCashbackEnabled: computed(() => !!webSockets.value?.isCashbackEnabled),
    isLoyaltyUpdateEnabled: computed(() => !!webSockets.value?.isLoyaltyUpdateEnabled),
    isCbcBonusWithdrawalAmountEnabled: computed(() => !!webSockets.value?.isCbcBonusWithdrawalAmountEnabled),
    isOnDepositEnabled: computed(() => !!webSockets.value?.isOnDepositEnabled),
    isSocketStickyBonusStatus: computed(() => !!webSockets.value?.isSocketStickyBonusStatus),
    isLoyaltyProgramEnabled: computed(() => !!webSockets.value?.isLoyaltyProgramEnabled),
    isWheelNotificationEnabled: computed(() => !!webSockets.value?.isWheelNotificationEnabled),

    isFlexibleFreeSpinBetLimitNotificationEnabled: computed(() => !!webSockets.value?.isFlexibleFreeSpinBetLimitNotificationEnabled),
    isFastTrackEnabled: computed(() => !!webSockets.value?.isFastTrackEnabled),
  };
}
