import type { RouteLocationRaw } from 'vue-router';

import { RouteName, CasinoRouteName } from '@leon-hub/routing-config-names';
import { LobbyGameType } from '@leon-hub/api-sdk';

import type { EgsGroup } from 'web/src/modules/egs/types';

export default function getLobbyLocationByGroup(group: EgsGroup): RouteLocationRaw {
  if (process.env.VUE_APP_FEATURE_CASINO_DISABLED) {
    return {
      name: RouteName.QUICK_BETS,
    };
  }

  let routeName;

  switch (group.type) {
    case LobbyGameType.LIVE:
      routeName = CasinoRouteName.CASINO_LIVE_LOBBY;
      break;
    case LobbyGameType.BETGAMES:
      return {
        name: CasinoRouteName.CASINO_BETGAMES,
      };
    case LobbyGameType.FASTGAMES:
      return {
        name: RouteName.QUICK_BETS,
      };
    case LobbyGameType.SLOTS:
    default:
      routeName = CasinoRouteName.CASINO_LOBBY;
      break;
  }

  return {
    name: routeName,
    params: {
      groupUrl: group.url,
    },
  };
}
