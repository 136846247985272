import type { RouteLocationNormalized } from 'vue-router';

import { HomePageType } from '@leon-hub/api-sdk';
import { RouteName, CasinoRouteName } from '@leon-hub/routing-config-names';

export default function isCasinoLobbyRouteName(
  routeName?: RouteLocationNormalized['name'],
  homePageType?: HomePageType,
): boolean {
  return !!routeName && (([
    CasinoRouteName.CASINO_LIVE_LOBBY,
    CasinoRouteName.CASINO_LOBBY,
    CasinoRouteName.CASINO_MY_GAMES,
    RouteName.QUICK_BETS,
  ] as string[]).includes(String(routeName))
    || (!!homePageType
      && [HomePageType.SLOTS, HomePageType.LIVE_GAMES].includes(homePageType)
      && routeName === RouteName.HOME));
}
