import { defineStore } from 'pinia';
import {
  computed,
  ref,
  watch,
} from 'vue';

import type { AppflowConfig } from '@leon-hub/api-sdk';
import {
  AppflowUpdateMethod,
  helpChatTypeNone,
  HomePageType,
  Web2FontFamily,
  ZetaDcpConfigType,
} from '@leon-hub/api-sdk';
import { toBoolean } from '@leon-hub/utils';

import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useSyncState } from 'web/src/modules/core/submodules/sync-state';

import type { SiteConfigDocument } from './types';
import useModuleTimersStore from './useModuleTimersStore';
import fetchSiteConfigSettings from './utils/fetchSiteConfigSettings';
import getDefaultCaptchaConfig from './utils/getDefaultCaptchaConfig';

export const useSiteConfigStore = defineStore('site-config', () => {
  const DEFAULT_SEARCH_SPORTLINE_MINCHARS = 3;
  const DEFAULT_SUGGESTED_EMAIL_REGISTRATION_TIMEOUT = 60;
  const DEFAULT_SMS_RESEND_CODE_TIMER = 90;

  const { isLoggedIn } = useIsLoggedIn();

  useSyncState((silent) => updateSiteConfigSettings({ silent }), 'site-config');

  // State
  const siteConfig = ref<Partial<SiteConfigDocument>>(Object.freeze({
    captcha: getDefaultCaptchaConfig(),
  }));

  // Getters
  const errorHandlerSettings = computed(() => siteConfig.value.errorHandler?.settings);
  const sentryOptions = computed(() => {
    const options: Record<string, unknown> = {};
    const errorHandlerSettingsValue = errorHandlerSettings.value;

    if (errorHandlerSettingsValue) {
      for (const parameter of errorHandlerSettingsValue.params) {
        Object.assign(options, {
          [parameter.key]: toBoolean(parameter.value),
        });
      }
    }

    return options;
  });
  const zetaDSPConfig = computed(() => siteConfig.value.zetaDcp?.configs ?? []);
  const widgetsBlock = computed(() => siteConfig.value.widgets);
  const landing = computed(() => siteConfig.value.landing);
  const jsBundlesCDNUrl = computed(() => siteConfig.value.settings?.jsBundlesCDNUrl);
  const accessOptionsBlock = computed(() => siteConfig.value.accessOptions);

  const { setDefaultTimeout, setModuleTimeouts } = useModuleTimersStore();

  async function updateSiteConfigSettings({ silent }: { silent?: boolean } = {}): Promise<void> {
    handleSiteConfigSettings(await fetchSiteConfigSettings({ silent }));
  }

  // Actions
  function handleSiteConfigSettings(result: Partial<SiteConfigDocument>): void {
    const mergeResult: Partial<SiteConfigDocument> = result.mobileAppCordova
      ? {
          ...result,
          mobileAppCordova: {
            ...result.mobileAppCordova,
            mobileAppUpdateConfigCustomerSpecific: isLoggedIn.value
              ? result.mobileAppCordova.mobileAppUpdateConfigCustomerSpecific
              : {
                  ...result.mobileAppCordova.mobileAppUpdateConfigCustomerSpecific,
                  isUpdateAvailable: false,
                  isApkUpdateMandatory: false,
                },
          },
        }
      : result;

    siteConfig.value = Object.freeze({
      ...siteConfig.value,
      ...mergeResult,
    });

    if (result.settings) {
      setDefaultTimeout(result.settings.modulesDefaultUpdateInterval);

      if (result.settings.modulesCustomUpdateInterval) {
        setModuleTimeouts(result.settings.modulesCustomUpdateInterval.reduce(
          (accumulator: Record<string, number>, item) => {
            accumulator[item.name] = item.interval;
            return accumulator;
          },
          {},
        ));
      }
    }
  }

  function init(): void {
    if (!process.env.VUE_APP_BUNDLER_NUXT
      && !process.env.VUE_APP_PRERENDER
      && !process.env.VUE_APP_PLATFORM_CORDOVA
      && !process.env.VUE_APP_STATIC_CDN_URL) {
      watch(jsBundlesCDNUrl, (newJsBundlesCDNUrl) => {
        if (newJsBundlesCDNUrl) {
          // eslint-disable-next-line sonarjs/no-implicit-global
          __webpack_public_path__ = newJsBundlesCDNUrl;
        }
      }, {
        immediate: true,
      });
    }
  }

  init();

  return {
    // Getters
    isThemeSwitcherEnabled: computed(() => !!siteConfig.value.settings?.themeSwitcherEnabled),
    themes: computed(() => siteConfig.value.settings?.themes ?? []),
    bookmakerRatingsUrl: computed(() => siteConfig.value.socialNetworks?.bookmakerRatingsUrl),
    socialMediaRssUrl: computed(() => siteConfig.value.socialNetworks?.socialMediaRssUrl),
    socialMediaVkUrl: computed(() => siteConfig.value.socialNetworks?.socialMediaVkUrl),
    socialMediaInstagramUrl: computed(() => siteConfig.value.socialNetworks?.socialMediaInstagramUrl),
    socialMediaTelegramUrl: computed(() => siteConfig.value.socialNetworks?.socialMediaTelegramUrl),
    socialMediaTwitterUrl: computed(() => siteConfig.value.socialNetworks?.socialMediaTwitterUrl),
    untrustedDevicePasswordResetRedirectTimer: computed(() => siteConfig.value
      .settings
      ?.untrustedDevicePasswordResetRedirectTimer),
    appVloaderDelayMs: computed(() => siteConfig.value.settings?.appVloaderDelayMs),
    sportLineBlock: computed(() => siteConfig.value.sportLine),
    sportsbookUIVersion: computed(() => siteConfig.value.sportsbookUIVersion),
    loginTypes: computed(() => siteConfig.value.login?.configs),
    sportEventsBlock: computed(() => siteConfig.value.sportEvents),
    downtimeBlock: computed(() => siteConfig.value.downtime),
    licenseBlock: computed(() => siteConfig.value.license),
    errorHandlerSettings,
    sentryOptions,
    ignoredErrorsCommon: computed(() => siteConfig.value.errorHandler?.ignoredErrorsCommon ?? []),
    metricsToolsBlock: computed(() => siteConfig.value.metricsTools),
    slipBlock: computed(() => siteConfig.value.slip),
    supportBlock: computed(() => siteConfig.value.support),
    isOldFaqEnabled: computed(() => !!siteConfig.value.support?.faqEnabled),
    pushNotificationsBlock: computed(() => siteConfig.value.pushNotifications),
    pushNotificationsWebBlock: computed(() => siteConfig.value.pushNotificationsWeb),
    promotionsBlock: computed(() => siteConfig.value.promotions),
    bonusLinkLoyalty: computed(() => siteConfig.value.bonusWallet?.bonusLinkLoyalty),
    bonusLinkEgsLoyalty: computed(() => siteConfig.value.bonusWallet?.bonusLinkEgsLoyalty),
    isStartScreenBonusEnabled: computed(() => !!siteConfig.value.bonusWallet?.startBonusScreenEnabled),
    identificationBlock: computed(() => siteConfig.value.identification),
    popularSearchQueries: computed(() => siteConfig.value.popularSearchQueries?.popularSearchQueriesData ?? []),
    feedbackFormMaxChars: computed(() => siteConfig.value.settings?.feedbackFormMaxChars || 500),
    isBrowserFingerprintEnabled: computed(() => Boolean(siteConfig.value.security?.browserFingerprintingEnabled)),
    isFingerprintJsEnabled: computed(() => Boolean(siteConfig.value.fJs?.isEnabled)),
    faceTouchIDReminderFrequency: computed(() => siteConfig.value.security?.faceTouchIDReminderFrequency || 0),
    faceTouchIDReminderCount: computed(() => siteConfig.value.security?.faceTouchIDReminderCount || 0),
    g2svInactiveInterval: computed(() => siteConfig.value.security?.g2svInactiveInterval),
    phoneWrongAttemptsLimit: computed(() => siteConfig.value.security?.phoneWrongAttemptsLimit || 1),
    pinCodeRequestedPlaces: computed(() => siteConfig.value.security?.pinCodeRequestedPlaces ?? null),
    pinCodeModalShowLimit: computed(() => siteConfig.value.security?.pinCodeModalShowLimit || 0),
    pinCodeWrongAttemptsLimit: computed(() => siteConfig.value.security?.pinCodeWrongAttemptsLimit || 0),
    isShieldEnabled: computed(() => !!siteConfig.value.security?.shieldEnabled),
    helmetAPIUrl: computed<string | undefined>(() => siteConfig.value.security?.helmetAPIUrl || undefined),
    isLexGuardEnabled: computed(() => !!siteConfig.value.security?.lexGuardEnabled),
    lexGuardAPIUrl: computed<string | undefined>(() => siteConfig.value.security?.lexGuardAPIUrl || undefined),
    lexGuardOrgId: computed<string | undefined>(() => siteConfig.value.security?.lexGuardOrgId || undefined),
    lexGuardProfilingSubdomain: computed<string | undefined>(() => siteConfig.value.security?.lexGuardProfilingSubdomain || undefined),
    searchMinCharsSize: computed(() => siteConfig.value.search?.searchSportlineMinchars
      || DEFAULT_SEARCH_SPORTLINE_MINCHARS),
    mobileBlock: computed(() => siteConfig.value.mobileBlocks),
    mobileAppCordovaBlock: computed(() => siteConfig.value.mobileAppCordova),
    isAndroidAppDownloadEnabled: computed(() => siteConfig.value.mobileAppCordova?.mobileAppAndroidDownloadEnabled ?? false),
    isTPWidgetEnabled: computed(() => siteConfig.value.tpWidget?.isTPWidgetEnabled ?? false),
    tpWidgetLandingUrl: computed(() => siteConfig.value.tpWidget?.tpWidgetLandingUrl),
    apkUpdateCheckInterval: computed(() => siteConfig.value.mobileAppCordova?.mobile5UpdateCheckPeriod),
    apkCommonUpdateData: computed(() => siteConfig.value.mobileAppCordova?.mobileAppUpdateConfigGeneric),
    apkCustomerUpdateData: computed(() => siteConfig.value.mobileAppCordova?.mobileAppUpdateConfigCustomerSpecific),
    appflowConfig: computed<AppflowConfig>(() => ({
      isEnabled: siteConfig.value.appflow?.config?.isEnabled ?? false,
      doReset: siteConfig.value.appflow?.config?.doReset ?? false,
      appId: siteConfig.value.appflow?.config?.appId ?? '',
      channel: siteConfig.value.appflow?.config?.channel ?? '',
      maxVersions: siteConfig.value.appflow?.config?.maxVersions ?? 2,
      minBackgroundDuration: siteConfig.value.appflow?.config?.minBackgroundDuration ?? 300,
      updateMethod: siteConfig.value.appflow?.config?.updateMethod ?? AppflowUpdateMethod.NONE,
      host: siteConfig.value.appflow?.config?.host ?? '',
      debug: Boolean(siteConfig.value.appflow?.config?.debug),
    })),
    domainsCheckConfig: computed(() => ({
      checkInterval: siteConfig.value.domainCheck?.checkInterval,
      msgMaxSizeBytes: siteConfig.value.domainCheck?.msgMaxSizeBytes,
      domains: siteConfig.value.domainCheck?.domains,
    })),
    appDownloadAdBlock: computed(() => siteConfig.value.appDownloadAd),
    appsFlyerSupportedSdkEvents: computed(() => (siteConfig.value.registration?.appsFlyerSupportedSdkEvents ?? [])),
    smsResendCodeTimer: computed(() => siteConfig.value.registration?.smsResendCodeTimer
      || DEFAULT_SMS_RESEND_CODE_TIMER),
    phoneCodeLengthSettings: computed(() => {
      const phoneCodeLength = siteConfig.value.registration?.phoneCodeLength ?? [];
      const phoneCodeLengthEntries = phoneCodeLength.map((phoneCode) => [phoneCode.phoneCodeMethod, phoneCode.value]);
      return Object.fromEntries(phoneCodeLengthEntries);
    }),
    bonusCodeInputFormStatus: computed(() => siteConfig.value.bonusWallet?.bonusCodeInputFormStatus),
    zetaDSPConfig,
    zetaDSPisEnabled: computed(() => siteConfig.value.zetaDcp?.isEnabled ?? false),
    zetaDSPMainType: computed(() => zetaDSPConfig.value.find((item) => item.type === ZetaDcpConfigType.MAIN)),
    isCasAuthEnabled: computed(() => Boolean(siteConfig.value.sso?.enabled)),
    googlePlayIconUrl: computed(() => siteConfig.value.settings?.googlePlayIconUrl || ''),
    appleStoreIconUrl: computed(() => siteConfig.value.settings?.appleStoreIconUrl || ''),
    linkCdnUrl: computed(() => siteConfig.value.settings?.linkCDNUrl || ''),
    sportradarCounterId: computed(() => siteConfig.value.sportradar?.counterId),
    fastTrackBlock: computed(() => siteConfig.value.fastTrack),
    fastGamesBlock: computed(() => siteConfig.value.fastGames),
    firebaseBlock: computed(() => siteConfig.value.firebase),
    widgetsBlock,
    widgetIframeUrl: computed(() => widgetsBlock.value?.iframeUrl),
    sportradar: computed(() => siteConfig.value.sportradar),
    captcha: computed(() => siteConfig.value.captcha),
    externalGames: computed(() => siteConfig.value.externalGames),
    virtualSport: computed(() => siteConfig.value.virtualSport),
    egsSkeletons: computed(() => siteConfig.value.egsSkeletons),
    vpnLoader: computed(() => siteConfig.value.vpnLoader),
    isSportradarLiveMatchTrackerEnabled: computed(() => !!widgetsBlock.value?.sportradarLiveMatchTrackerEnabled),
    isSportradarStatisticsEnabled: computed(() => !!widgetsBlock.value?.sportradarStatisticsEnabled),
    isSportradarLiveScoreEnabled: computed(() => !!widgetsBlock.value?.sportradarLiveScoreEnabled),
    isServiceSuspended: computed(() => siteConfig.value.settings?.isServiceSuspended),
    isPhoneTabEnabledOnPasswordRestorePage: computed(() => !!siteConfig.value
      .registration
      ?.isPhoneTabEnabledOnPasswordRestorePage),
    isEmailTabEnabledOnPasswordRestorePage: computed(() => !!siteConfig.value
      .registration
      ?.isEmailTabEnabledOnPasswordRestorePage),
    isSumSubWebSdkInProdMode: computed(() => !!siteConfig.value.sumSub?.isWebSdkInProdMode),
    sumSubUrl: computed(() => siteConfig.value.sumSub?.sdkUrl || ''),
    isSubscriptionBlockEnabled: computed(() => !!siteConfig.value.profile?.subscriptionBlockEnabled),
    isProfileReferralProgramEnabled: computed(() => !!siteConfig.value.profile?.profileReferralProgramEnabled),
    isReferralProgramMainBlockEnabled: computed(() => !!siteConfig.value.profile?.referralProgramMainBlockEnabled),
    isProfileBetsCounterEnabled: computed(() => !!siteConfig.value.profile?.profileBetsCounterEnabled),
    isProfilePersonalDataDisallowUserInputEnabled: computed(() => !!siteConfig.value.profile?.profilePersonalDataDisallowUserInputEnabled),
    isFaqBlockEnabled: computed(() => !!siteConfig.value.faq?.faqEnabled),
    affiliateMigrationLink: computed(() => siteConfig.value.settings?.affiliateMigrationLink || ''),
    isSuggestedEmailRegistrationEnabled: computed(() => !!siteConfig.value
      .registration
      ?.isSuggestedEmailRegistrationEnabled),
    suggestedEmailRegistrationTimeout: computed(() => siteConfig.value.registration?.suggestedEmailRegistrationTimeout
      || DEFAULT_SUGGESTED_EMAIL_REGISTRATION_TIMEOUT),
    isLivechatAnonymEnabled: computed(() => !!siteConfig.value.support?.isLivechatAnonymEnabled),
    helpChatType: computed(() => siteConfig.value.support?.helpChatType ?? helpChatTypeNone),
    isResponsibleGamblingFeedbackEnabled: computed(() => !!siteConfig.value
      .support
      ?.isResponsibleGamblingFeedbackEnabled),
    isRulesTitleEnabled: computed(() => !!siteConfig.value.rules?.isTitleEnabled),
    isRulesNavigationOpen: computed(() => !!siteConfig.value.rules?.isNavigationOpen),
    isRulesSearchEnabled: computed(() => !!siteConfig.value.rules?.isSearchEnabled),
    isRulesMobileTabsEnabled: computed(() => !!siteConfig.value.rules?.isMobileTabsEnabled),
    loyaltyProgramEgsStatus: computed(() => siteConfig.value.bonusWallet?.loyaltyProgramEgsStatus),
    postponedModalsShowTimeout: computed(() => siteConfig.value.fastTrack?.modalsShowTimeout || 3000),
    bonusesEnable: computed(() => siteConfig.value.bonuses?.bonusesEnable),
    bonusesPolling: computed(() => siteConfig.value.bonuses?.bonusesPolling),
    isLeonShopEnabled: computed(() => !!siteConfig.value.bonuses?.isLeonShopEnabled),
    isEgsStoryBannerEnabled: computed(() => !!siteConfig.value.externalGames?.isStoryBannerEnabled),
    delayBeforeNewBonusesListRequest: computed(() => siteConfig.value.bonusWallet?.delayBeforeNewBonusesListRequest),
    idleRegistrationProps: computed(() => ({
      idleRedirectCount: siteConfig.value.registration?.idleRedirectCount || 0,
      idleTimeToRedirect: siteConfig.value.registration?.idleTimeToRedirect || 0,
      marketingLinkIdleRedirect: siteConfig.value.registration?.marketingLinkIdleRedirect || 0,
      marketingLinkParam: siteConfig.value.registration?.marketingLinkParam,
    })),
    isPushNotificationsEnabled: computed(() => !!siteConfig.value.pushNotifications?.enabled),
    isForcedLayoutEnabled: computed(() => !!siteConfig.value.settings?.forcedLayoutEnabled),
    showModalOnLeaveEnabled: computed(() => !!siteConfig.value.registration?.showModalOnLeaveEnabled),
    fingerprintJsBlock: computed(() => siteConfig.value.fJs),
    isRegistrationDisabled: computed(() => !!siteConfig.value.registration?.isRegistrationDisabled),
    formsEmailHintsEnabled: computed(() => !!siteConfig.value.registration?.formsEmailHintsEnabled),
    formsEmailHints: computed(() => siteConfig.value.registration?.formsEmailHints ?? []),
    registrationBanners: computed(() => siteConfig.value.registration?.footerBannerLogotypesByLocale ?? []),
    isOldFooterLogotypesEnabled: computed(() => !!siteConfig.value.footer?.isFooterOldLogotypesEnabled),
    isFooterLogotypesEnabled: computed(() => !!siteConfig.value.footer?.isFooterLogotypesEnabled),
    isFooterSocialNetworksEnabled: computed(() => !!siteConfig.value.footer?.isFooterSocialNetworksEnabled),
    footerLogotypesBlock: computed(() => siteConfig.value.footer),
    isPromotionsHighlightLinkEnabled: computed(() => !!siteConfig.value.promotions?.isPromotionsHighlightLinkEnabled),
    isEgsLoyaltyEnabled: computed(() => !!siteConfig.value.loyalty?.isEgsLoyaltyEnabled),
    isEgsLoyaltyProgramLvlUpModelEnabled: computed(() => !!siteConfig.value
      .loyalty
      ?.isEgsLoyaltyProgramLvlUpModelEnabled),
    isHelpEmailEnabled: computed(() => !!siteConfig.value.support?.isHelpEmailEnabled),
    landing,
    isLandingWelcomeEnabled: computed(() => !!landing.value?.isLandingWelcomeEnabled),
    isLandingAndroidEnabled: computed(() => !!landing.value?.isLandingAndroidEnabled),
    landingAndroidImageHeader: computed(() => landing.value?.landingAndroidImageHeader),
    landingAndroidImageGamblingFirst: computed(() => landing.value?.landingAndroidImageGamblingFirst),
    landingAndroidImageGamblingSecond: computed(() => landing.value?.landingAndroidImageGamblingSecond),
    landingAndroidImageGamblingThird: computed(() => landing.value?.landingAndroidImageGamblingThird),
    landingAndroidImageFree: computed(() => landing.value?.landingAndroidImageFree),
    landingAndroidImageBonusFirst: computed(() => landing.value?.landingAndroidImageBonusFirst),
    landingAndroidImageBonusSecond: computed(() => landing.value?.landingAndroidImageBonusSecond),
    landingAndroidImageBonusThird: computed(() => landing.value?.landingAndroidImageBonusThird),
    landingAndroidImageBackground: computed(() => landing.value?.landingAndroidImageBackground),
    landingAndroidImageFooter: computed(() => landing.value?.landingAndroidImageFooter),
    landingCouponsBackground: computed(() => landing.value?.landingCouponsBackground),
    landingCouponsMainImage: computed(() => landing.value?.landingCouponsMainImage),
    landingCouponsSecondaryImage: computed(() => landing.value?.landingCouponsSecondaryImage),
    landingFestEmbed: computed(() => landing.value?.landingFestEmbed),
    festPromoDetailsLink: computed(() => landing.value?.festPromoDetailsLink),
    androidSocialNetworks: computed(() => landing.value?.androidSocialNetworks),
    isPromotionsRequestNicknameEnabled: computed(() => !!siteConfig.value
      .promotions
      ?.isPromotionsRequestNicknameEnabled),
    isSumOfBonusAndBalanceEnabled: computed(() => !!siteConfig.value.header?.isHeaderBalanceEnabled),
    isVideoVerificationFeatureEnabled: computed(() => !!siteConfig.value.verification?.isVideoVerificationEnabled),
    promoActionLinks: computed(() => landing.value?.promoActionLinks ?? []),
    welcomeEgsCategory: computed(() => landing.value?.welcomeEgsCategory),
    isLandingFestivalEnabled: computed(() => !!landing.value?.isLandingFestivalEnabled),
    isLandingVipEnabled: computed(() => !!landing.value?.isLandingVipEnabled),
    landingVipFaqItems: computed(() => landing.value?.landingVipFaqItems ?? []),
    isAdventCalendarEnabled: computed(() => !!landing.value?.isAdventCalendarEnabled),
    landingAdventBackgroundImage: computed(() => landing.value?.landingAdventBackgroundImage),
    landingAndroidAnimationRightTop: computed(() => landing.value?.landingAndroidAnimationRightTop),
    landingAndroidAnimationRightMiddle: computed(() => landing.value?.landingAndroidAnimationRightMiddle),
    landingAndroidAnimationRightBottom: computed(() => landing.value?.landingAndroidAnimationRightBottom),
    landingAndroidAnimationLeftTop: computed(() => landing.value?.landingAndroidAnimationLeftTop),
    landingAndroidAnimationLeftBottom: computed(() => landing.value?.landingAndroidAnimationLeftBottom),
    isPartnersEnabled: computed(() => !!siteConfig.value.partner?.isPartnersEnabled),
    isPartnersV2Enabled: computed(() => !!siteConfig.value.partner?.isPartnersV2Enabled),
    isPromotionsLikeEnabled: computed(() => !!siteConfig.value.promotions?.isPromotionsLikeEnabled),
    isBonusListBeforeDepositEnabled: computed(() => siteConfig.value.deposits?.bonusListBeforeDepositEnabled ?? false),
    promotionsLikeFeedbackCountdown: computed(() => siteConfig.value.promotions?.promotionsLikeFeedbackCountdown || 0),
    isWeb2BonusCashbackWidgetEnabled: computed(() => !!siteConfig.value.bonuses?.isWeb2BonusCashbackWidgetEnabled),
    isFomoTopBarIconEnabled: computed(() => !!siteConfig.value.bonuses?.isFomoTopBarIconEnabled),
    isSeoBetCmsSpintaxEnabled: computed(() => !!siteConfig.value.settings?.isSeoBetCmsSpintaxEnabled),
    isMetaBetCmsSpintaxEnabled: computed(() => !!siteConfig.value.settings?.isMetaBetCmsSpintaxEnabled),
    isLanguageSelectorFlagsEnabled: computed(() => !!siteConfig.value.settings?.isLanguageSelectorFlagsEnabled),
    isPlayTimeControlEnabled: computed(() => !!siteConfig.value.settings?.isPlayTimeControlEnabled),
    webSockets: computed(() => siteConfig.value.webSockets),
    payments: computed(() => siteConfig.value.payments),
    millisToSleepAfterCountryChange: computed(() => (
      siteConfig.value.registration?.millisToSleepAfterCountryChange || 0
    )),
    homePageType: computed(() => siteConfig.value.routing?.homePageType ?? HomePageType.SPORTS),
    suggestedAmounts: computed(() => siteConfig.value.deposits?.suggestedAmounts ?? []),
    referralProgramSocialItems: computed(() => siteConfig.value?.profile?.referralProgramSocialItems ?? []),
    isActivityReporterEnabled: computed(() => siteConfig.value?.activityReporter?.activityReporterEnabled ?? false),
    doRedirectGuestWithAccountToLogin: computed(
      () => siteConfig.value?.guest?.doRedirectGuestWithAccountToLogin ?? false,
    ),
    redirectToLoginTimeout: computed(
      () => siteConfig.value?.guest?.redirectToLoginTimeout ?? 0,
    ),
    isTempCouponFeatureEnabled: computed(() => !!siteConfig.value?.promotions?.isTempCouponFeatureEnabled),
    tempCouponCampaignInterval: computed(() => siteConfig.value?.promotions?.tempCouponCampaignInterval ?? null),
    festivalCampaignId: computed(() => siteConfig.value?.promotions?.festivalCampaignId ?? null),
    festivalActionUrl: computed(() => siteConfig.value?.promotions?.festivalActionUrl ?? ''),
    festivalTournamentActionUrls: computed(() => siteConfig.value?.promotions?.festivalTournamentActionUrls ?? []),
    festivalCouponCampaignId: computed(() => siteConfig.value?.promotions?.festivalCouponCampaignId ?? null),
    responsibleGamblingV2Enabled: computed(() => (
      siteConfig.value.responsibleGambling?.responsibleGamblingV2Enabled ?? false
    )),
    selfExclusionOptions: computed(() => siteConfig.value.responsibleGambling?.selfExclusionOptions ?? []),
    responsibleGamblingRedirectEnabled: computed(() => siteConfig.value.responsibleGambling?.responsibleGamblingRedirectEnabled ?? false),
    fontFamily: computed(() => siteConfig.value.settings?.fontFamily ?? Web2FontFamily.DEFAULT),
    settingsBlock: computed(() => siteConfig.value.settings),
    isAccessOptionsEnabled: computed(() => accessOptionsBlock.value?.areAccessOptionsEnabled),
    accessOptionsItems: computed(() => accessOptionsBlock.value?.accessOptionsItems),
    accessOptionsSocialItems: computed(() => accessOptionsBlock.value?.accessOptionsSocialItems),
    isVipFastTrackEnabled: computed(() => siteConfig.value.vipFastTrack?.isVipFastTrackEnabled),
    isAchievementEnabled: computed(() => siteConfig.value.achievements?.isAchievementEnabled),
    isAvatarsEnabled: computed(() => siteConfig.value.achievements?.isAvatarsEnabled),
    sharingConfigs: computed(() => siteConfig.value.achievements?.sharingConfigs),
    isRegistrationHintMessageEnabled: computed(() => siteConfig.value.registration?.isRegistrationHintMessageEnabled),
    registrationHintMessageLink: computed(() => siteConfig.value.registration?.registrationHintMessageLink),
    debugData: computed(() => siteConfig.value.debug?.debugData),
    isAccHistoryV2AllTabEnabled: computed(() => siteConfig.value.web2AccountHistory?.isAccHistoryV2AllTabEnabled),
    isAccHistoryV2VsportTabEnabled: computed(() => siteConfig.value.web2AccountHistory?.isAccHistoryV2VsportTabEnabled),
    isAccHistoryV2SportsbookTabEnabled: computed(() => siteConfig.value.web2AccountHistory?.isAccHistoryV2SportsbookTabEnabled),
    isAccHistoryV2EgsTabEnabled: computed(() => siteConfig.value.web2AccountHistory?.isAccHistoryV2EgsTabEnabled),
    isAccHistoryV2MarketingTabEnabled: computed(() => siteConfig.value.web2AccountHistory?.isAccHistoryV2MarketingTabEnabled),
    isAccHistoryV2PaymentsTabEnabled: computed(() => siteConfig.value.web2AccountHistory?.isAccHistoryV2PaymentsTabEnabled),
    bannerSliderDotsCount: computed(() => siteConfig.value.bonuses?.bannerSliderDotsCount),
    isEgsTournamentsPromoModalEnabled: computed(() => !!siteConfig.value.pushNotificationsWeb?.isEgsTournamentsPromoModalEnabled),
    // Actions
    updateSiteConfigSettings,
    handleSiteConfigSettings,
  };
});

export default useSiteConfigStore;
