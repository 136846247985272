import { EditionValueOsAndroid, EditionValueOsWindows, EditionValueOsIos, EditionValueOsMacos, EditionValueOsLinux, EditionValueOsOther, EditionValueBrowserChrome, EditionValueBrowserFirefox, EditionValueBrowserNo, EditionValueBrowserEdge, EditionValueBrowserSafari, EditionValueBrowserOpera, EditionValueBrowserYandex, EditionValueLayoutPhone, EditionValueLayoutDesktop, runtimeEditionsConfig, envPrefix, EditionKey } from "@leon-hub/environment-editions";
import { isString } from "@leon-hub/guards";
import { QueryParameterName } from "@leon-hub/query-manager";
import { getQueryParams, getUserAgent } from "@leon-hub/service-locator-env";
import { EnvValueEnabled, createEnvVarSetter } from "@leon-hub/environment-common";
class DeviceMeta {
  static getUserAgent() {
    const fallback = "<unknown-browser>";
    try {
      const { opera, navigator } = window;
      return (navigator == null ? void 0 : navigator.userAgent) || (navigator == null ? void 0 : navigator.vendor) || (isString(opera) ? opera : fallback);
    } catch {
    }
    return fallback;
  }
  static isPhone(userAgentParameter) {
    const userAgent = userAgentParameter || DeviceMeta.getUserAgent();
    const userAgentPart = userAgent.slice(0, 4);
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(userAgent) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([\- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([\- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[23]|n30(0|2)|n50([025])|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8c]))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([\-01])|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(userAgentPart);
  }
  static isPhoneOrTablet(userAgentParameter) {
    const userAgent = userAgentParameter || DeviceMeta.getUserAgent();
    const userAgentPart = userAgent.slice(0, 4);
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(userAgent) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([\- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([\- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[23]|n30(0|2)|n50([025])|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8c]))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([\-01])|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(userAgentPart);
  }
  static isTablet(userAgentParameter) {
    const userAgent = userAgentParameter || DeviceMeta.getUserAgent();
    if (userAgent.includes("Mac") && process.env.VUE_APP_RENDERING_CSR && "ontouchend" in document) {
      return true;
    }
    return DeviceMeta.isPhoneOrTablet(userAgent) && !DeviceMeta.isPhone(userAgent);
  }
  static getOs(userAgentParameter) {
    try {
      const userAgent = userAgentParameter || DeviceMeta.getUserAgent();
      if (userAgent.includes("Android")) {
        return EditionValueOsAndroid;
      }
      if (userAgent.includes("Win32") || userAgent.includes("Win64") || userAgent.includes("Windows") || userAgent.includes("WinCE")) {
        return EditionValueOsWindows;
      }
      if (userAgent.includes("iPhone") || userAgent.includes("iPad") || userAgent.includes("iPod")) {
        return EditionValueOsIos;
      }
      if (userAgent.includes("Macintosh") || userAgent.includes("MacIntel") || userAgent.includes("MacPPC") || userAgent.includes("Mac68K")) {
        return EditionValueOsMacos;
      }
      if (userAgent.includes("Linux")) {
        return EditionValueOsLinux;
      }
    } catch {
    }
    return EditionValueOsOther;
  }
  static getBrowser(userAgentParameter) {
    const userAgent = userAgentParameter || DeviceMeta.getUserAgent();
    if (userAgent.includes("Chrome")) {
      return EditionValueBrowserChrome;
    }
    if (userAgent.includes("Firefox")) {
      return EditionValueBrowserFirefox;
    }
    if (userAgent.includes("MSIE")) {
      return EditionValueBrowserNo;
    }
    if (userAgent.includes("Edge")) {
      return EditionValueBrowserEdge;
    }
    if (userAgent.includes("Safari")) {
      return EditionValueBrowserSafari;
    }
    if (userAgent.includes("Opera")) {
      return EditionValueBrowserOpera;
    }
    if (userAgent.includes("YaBrowser")) {
      return EditionValueBrowserYandex;
    }
    return EditionValueBrowserNo;
  }
  static getLayout(userAgentParameter) {
    const userAgent = userAgentParameter || DeviceMeta.getUserAgent();
    if (DeviceMeta.isPhone(userAgent)) {
      return EditionValueLayoutPhone;
    }
    return EditionValueLayoutDesktop;
  }
}
const createPrerenderEnv = () => {
  const queryParams = getQueryParams();
  if (queryParams[QueryParameterName.PRERENDER] === EnvValueEnabled) {
    return {
      VUE_APP_PRERENDER: EnvValueEnabled
    };
  }
  return {};
};
const setRuntimeEditionEnvVar = /* @__PURE__ */ createEnvVarSetter(runtimeEditionsConfig, envPrefix);
function createRuntimeEnvironment() {
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    return {};
  }
  const userAgent = getUserAgent();
  let env = {};
  if (!process.env.VUE_APP_BUNDLER_NUXT || process.env.NODE_ENV !== "production") {
    env = setRuntimeEditionEnvVar(env, EditionKey.LAYOUT, DeviceMeta.getLayout(userAgent));
  }
  env = setRuntimeEditionEnvVar(env, EditionKey.BROWSER, DeviceMeta.getBrowser(userAgent));
  env = setRuntimeEditionEnvVar(env, EditionKey.OS, DeviceMeta.getOs(userAgent));
  return env;
}
function updateEnvironment({
  environment
}) {
  Object.assign(
    environment,
    createPrerenderEnv(),
    createRuntimeEnvironment()
  );
}
export {
  DeviceMeta,
  createPrerenderEnv,
  createRuntimeEnvironment,
  updateEnvironment
};
