var AccountHistoryRouteName = /* @__PURE__ */ ((AccountHistoryRouteName2) => {
  AccountHistoryRouteName2["ACCOUNT_HISTORY_ALL"] = "account-history-all";
  AccountHistoryRouteName2["ACCOUNT_HISTORY_EGS"] = "account-history-egs";
  AccountHistoryRouteName2["ACCOUNT_HISTORY_EGS_ROUND"] = "account-history-egs-round";
  AccountHistoryRouteName2["ACCOUNT_HISTORY_ROUND_DETAILS"] = "account-history-round-details";
  AccountHistoryRouteName2["ACCOUNT_HISTORY_MARKETING"] = "account-history-marketing";
  AccountHistoryRouteName2["ACCOUNT_HISTORY_MARKETING_DETAILS"] = "account-history-marketing-details";
  AccountHistoryRouteName2["ACCOUNT_HISTORY_PAYMENT"] = "account-history-payment";
  AccountHistoryRouteName2["ACCOUNT_HISTORY_PAYMENT_DETAILS"] = "account-history-payment-details";
  AccountHistoryRouteName2["ACCOUNT_HISTORY_SPORTSBOOK"] = "account-history-sportsbook";
  AccountHistoryRouteName2["ACCOUNT_HISTORY_SPORTSBOOK_DETAILS"] = "account-history-sportsbook-details";
  AccountHistoryRouteName2["ACCOUNT_HISTORY_VSPORT"] = "account-history-vsport";
  AccountHistoryRouteName2["ACCOUNT_HISTORY_VSPORT_DETAILS"] = "account-history-vsport-details";
  return AccountHistoryRouteName2;
})(AccountHistoryRouteName || {});
/* @__PURE__ */ Object.values(AccountHistoryRouteName);
var ProfileRouteName = /* @__PURE__ */ ((ProfileRouteName2) => {
  ProfileRouteName2["ACHIEVEMENTS"] = "achievements";
  ProfileRouteName2["AVATAR"] = "avatar";
  ProfileRouteName2["ACHIEVEMENTS_NOTIFICATION"] = "achievements-notification";
  ProfileRouteName2["ACHIEVEMENTS_SHARE"] = "achievements-share";
  ProfileRouteName2["ACHIEVEMENTS_PUBLIC_PROFILE"] = "achievements-public-profile";
  ProfileRouteName2["ACHIEVEMENTS_LIST"] = "achievements-list";
  ProfileRouteName2["ACHIEVEMENTS_DETAILS"] = "achievements-details";
  ProfileRouteName2["ACHIEVEMENTS_FILTER"] = "achievements-filter";
  ProfileRouteName2["PRIVACY"] = "privacy";
  return ProfileRouteName2;
})(ProfileRouteName || {});
const profileRouteNames = /* @__PURE__ */ Object.values(ProfileRouteName);
function isProfileRouteName(value) {
  return profileRouteNames.includes(value);
}
var SportlineRouteName = /* @__PURE__ */ ((SportlineRouteName2) => {
  SportlineRouteName2["SPORTLINE_LIVE_EVENTS"] = "sportline-live-events";
  SportlineRouteName2["SPORTLINE_PREMATCH_EVENTS"] = "sportline-prematch-events";
  SportlineRouteName2["SPORTLINE_PREMATCH_TODAY"] = "sportline-prematch-today";
  SportlineRouteName2["SPORTLINE_TOP_EVENTS"] = "sportline-top-events";
  SportlineRouteName2["SPORTLINE_TOP_LEAGUE"] = "sportline-top-league";
  SportlineRouteName2["SPORTLINE_LIVE_LEAGUE"] = "sportline-live-league";
  SportlineRouteName2["SPORTLINE_VIRTUAL_SPORT"] = "sportline-virtual-sport";
  SportlineRouteName2["SPORTLINE_CYBERSPORT"] = "sportline-cybersport";
  SportlineRouteName2["SPORT_REGION"] = "sport-region";
  SportlineRouteName2["SPORT_LEAGUE"] = "sport-league";
  SportlineRouteName2["SPORT_EVENT_DETAILS"] = "sport-event-details";
  SportlineRouteName2["SPORTLINE_SELECT_ODDS"] = "sportline-select-odds";
  SportlineRouteName2["SLIP_SHARE"] = "share-slip";
  SportlineRouteName2["GET_SHARED_SLIP"] = "betslip";
  SportlineRouteName2["SLIP_WIN_BOOST_INFO"] = "slip-win-boost-info";
  SportlineRouteName2["QUICK_BETS"] = "quick-bets";
  SportlineRouteName2["QUICK_BETS_DETAILS"] = "quick-bets-details";
  return SportlineRouteName2;
})(SportlineRouteName || {});
const sportlineRouteNames = /* @__PURE__ */ Object.values(SportlineRouteName);
function isSportlineRouteName(value) {
  return sportlineRouteNames.includes(value);
}
var PromoRouteName = /* @__PURE__ */ ((PromoRouteName2) => {
  PromoRouteName2["PROMOTIONS"] = "promotions";
  PromoRouteName2["PROMOTION_DETAILS"] = "promotion-details";
  PromoRouteName2["BONUS_WALLET"] = "bonus-wallet";
  PromoRouteName2["TOURNAMENTS"] = "tournaments";
  PromoRouteName2["TOURNAMENT_DETAILS"] = "tournament-details";
  return PromoRouteName2;
})(PromoRouteName || {});
const promoRouteNames = /* @__PURE__ */ Object.values(PromoRouteName);
function isPromoRouteName(value) {
  return promoRouteNames.includes(value);
}
var BaseRouteName = /* @__PURE__ */ ((BaseRouteName2) => {
  BaseRouteName2["HOME"] = "home";
  BaseRouteName2["DEV_DASHBOARD"] = "dev";
  BaseRouteName2["DEFAULT"] = "default";
  BaseRouteName2["FULL"] = "full";
  BaseRouteName2["FULL_WITH_TOP_BAR"] = "full-with-top-bar";
  BaseRouteName2["DESKTOP_DEFAULT"] = "desktop-default";
  BaseRouteName2["DESKTOP_LEFT_COL"] = "desktop-left-col";
  BaseRouteName2["DESKTOP_RIGHT_COL"] = "desktop-right-col";
  BaseRouteName2["PAGES"] = "pages";
  BaseRouteName2["FORMS"] = "forms";
  BaseRouteName2["LOGIN"] = "login";
  BaseRouteName2["FLOW_LOGIN"] = "flow-login";
  BaseRouteName2["THEME_SELECT_PAGE"] = "theme-select";
  BaseRouteName2["THEME_SELECT_MODAL"] = "theme-select-modal";
  BaseRouteName2["PUSH_NOTIFICATIONS_ONBOARDING"] = "push-notifications-onboarding";
  BaseRouteName2["PUSH_NOTIFICATIONS_BETS_ONBOARDING"] = "push-notifications-bets-onboarding";
  BaseRouteName2["START_SCREEN_BONUS"] = "start-screen-bonus";
  BaseRouteName2["RESTORE_PASSWORD"] = "restore-password";
  BaseRouteName2["RESTORE_PIN"] = "restore-pin";
  BaseRouteName2["RESTORE_PIN_PROFILE"] = "restore-pin-profile";
  BaseRouteName2["PIN_RESET"] = "reset-pin-code";
  BaseRouteName2["RESTORE_PASSWORD_APP"] = "restore-password-app";
  BaseRouteName2["RESTORE_PASSWORD_ENTER_NEW_ONE"] = "restore-password-enter-new-one";
  BaseRouteName2["IDENTIFICATION"] = "customer-identification";
  BaseRouteName2["IDENTIFICATION_COMPLETE"] = "identification-complete";
  BaseRouteName2["PRIVACY_POLICY"] = "privacy-policy";
  BaseRouteName2["WITHDRAWALS"] = "withdrawals";
  BaseRouteName2["WITHDRAWALS_DETAILS"] = "withdrawals-details";
  BaseRouteName2["NUMBER_CHECK"] = "number-check";
  BaseRouteName2["DEPOSITS"] = "deposits";
  BaseRouteName2["DEPOSITS_DETAILS"] = "deposits-details";
  BaseRouteName2["WITHDRAWALOK"] = "withdrawalok";
  BaseRouteName2["WITHDRAWALOK2"] = "withdrawalok2";
  BaseRouteName2["DEPOSITSOK"] = "depositok";
  BaseRouteName2["DEPOSITSOK2"] = "depositok2";
  BaseRouteName2["DEPOSITSFAIL"] = "depositsfail";
  BaseRouteName2["DEPOSITSFAIL2"] = "depositsfail2";
  BaseRouteName2["DEPOSITSPROCESS"] = "depositsprocess";
  BaseRouteName2["DEPOSITSPROCESS2"] = "depositsprocess2";
  BaseRouteName2["DEPOSITS_OVERVIEW"] = "deposits-overview";
  BaseRouteName2["WHAT_IS_CVV_CVC_CODE"] = "what-is-cvv-cvc-code";
  BaseRouteName2["SUPPORT"] = "support";
  BaseRouteName2["FEEDBACK"] = "feedback";
  BaseRouteName2["FEEDBACK_LEGACY"] = "feedback-legacy";
  BaseRouteName2["FEEDBACK_RATING"] = "feedback-rating";
  BaseRouteName2["CALLBACK"] = "callback";
  BaseRouteName2["RESPONSIBLE_GAMBLING_BETTING_LIMITS"] = "responsible-gambling-betting-limits";
  BaseRouteName2["RESPONSIBLE_GAMBLING_COMPREHENSIVE_SELF_EXCLUSION"] = "responsible-gambling-comprehensive-self-exclusion";
  BaseRouteName2["RESPONSIBLE_GAMBLING_DEPOSIT_LIMITS"] = "responsible-gambling-deposit-limits";
  BaseRouteName2["RESPONSIBLE_GAMBLING_INFO"] = "responsible-gambling-info";
  BaseRouteName2["RESPONSIBLE_GAMBLING_LIMITS"] = "responsible-gambling-limits";
  BaseRouteName2["RESPONSIBLE_GAMBLING_OPTIONS"] = "responsible-gambling-options";
  BaseRouteName2["RESPONSIBLE_GAMBLING_PARTIAL_RESTRICTION"] = "responsible-gambling-partial-restriction";
  BaseRouteName2["RESPONSIBLE_GAMBLING_SELF_EXCLUSION"] = "responsible-gambling-self-exclusion";
  BaseRouteName2["RESPONSIBLE_GAMBLING_TEST"] = "responsible-gambling-test";
  BaseRouteName2["RESPONSIBLE_GAMBLING_QUIZ"] = "responsible-gambling-quiz";
  BaseRouteName2["RESPONSIBLE_GAMBLING_TIME_IN_GAME"] = "responsible-gambling-time-in-game";
  BaseRouteName2["RESPONSIBLE_GAMBLING_TIME_IN_GAME_INFO"] = "responsible-gambling-time-in-game-info";
  BaseRouteName2["RESPONSIBLE_GAMBLING_SESSION_LIMIT"] = "responsible-gambling-session-limit";
  BaseRouteName2["RESPONSIBLE_GAMBLING_DEPOSIT_LIMIT"] = "responsible-gambling-deposit-limit";
  BaseRouteName2["RESPONSIBLE_GAMBLING_DEPOSIT_LIMIT_INFO"] = "responsible-gambling-deposit-limit-info";
  BaseRouteName2["RESPONSIBLE_GAMBLING_BET_LIMIT"] = "responsible-gambling-bet-limit";
  BaseRouteName2["RESPONSIBLE_GAMBLING_BET_LIMIT_INFO"] = "responsible-gambling-bet-limit-info";
  BaseRouteName2["RESPONSIBLE_GAMBLING_BREAK"] = "responsible-gambling-break";
  BaseRouteName2["RESPONSIBLE_GAMBLING_EXCLUSION"] = "responsible-gambling-exclusion";
  BaseRouteName2["RESPONSIBLE_GAMBLING_EXCLUSION_INFO"] = "responsible-gambling-exclusion-info";
  BaseRouteName2["RESPONSIBLE_GAMBLING_DELETE_ACCOUNT"] = "responsible-gambling-delete-account";
  BaseRouteName2["CUSTOMER_NOTIFICATIONS"] = "customer-notifications";
  BaseRouteName2["CUSTOMER_NOTIFICATION_DETAILS"] = "customer-notification-details";
  BaseRouteName2["CUSTOMER_IDENTIFICATION_STATUS"] = "customer-identification-status";
  BaseRouteName2["FAQ"] = "faq";
  BaseRouteName2["FAQ_DETAILS"] = "faq-details";
  BaseRouteName2["FAQ_CATEGORY"] = "faq-category";
  BaseRouteName2["FAQ_CATEGORY_ITEM"] = "faq-category-item";
  BaseRouteName2["PROFILE"] = "profile";
  BaseRouteName2["CUSTOMER_SETTINGS"] = "customer-settings";
  BaseRouteName2["CUSTOMER_VERIFICATION"] = "customer-verification";
  BaseRouteName2["CUSTOMER_VERIFICATION_SELECT_TIME"] = "customer-verification-select-time";
  BaseRouteName2["CUSTOMER_VERIFICATION_CALL"] = "customer-verification-call";
  BaseRouteName2["HELP"] = "help";
  BaseRouteName2["SEARCH"] = "search";
  BaseRouteName2["TWO_FACTOR_AUTHENTICATION"] = "two-factor-authentication";
  BaseRouteName2["TWO_FACTOR_AUTHENTICATION_REGENERATE"] = "two-factor-authentication-regenerate";
  BaseRouteName2["TWO_FACTOR_AUTHENTICATION_LOGIN"] = "two-factor-authentication-login";
  BaseRouteName2["ERROR_NOT_FOUND_404"] = "not-found";
  BaseRouteName2["ERROR_FORBIDDEN_403"] = "forbidden";
  BaseRouteName2["ERROR_SERVICE_UNAVAILABLE_503"] = "service-unavailable";
  BaseRouteName2["REDIRECT_SEO"] = "redirect-seo";
  BaseRouteName2["HISTORY"] = "history";
  BaseRouteName2["PASSWORD_CHANGE"] = "password-change";
  BaseRouteName2["CUSTOMER_SUBSCRIPTIONS"] = "customer-subscriptions";
  BaseRouteName2["IFRAME_WIDGETS"] = "iframe-widgets";
  BaseRouteName2["CUSTOMER_PERSONAL_DATA"] = "customer-personal-data";
  BaseRouteName2["CUSTOMER_TRANSACTIONS_HISTORY"] = "customer-transactions-history";
  BaseRouteName2["CUSTOMER_HISTORY_TRANSACTION_DETAILS"] = "customer-history-transaction-details";
  BaseRouteName2["BONUS_WITHDRAWALS"] = "bonus-withdrawals";
  BaseRouteName2["BONUS_CODE_ENTER"] = "bonus-code-enter";
  BaseRouteName2["BONUSES"] = "bonuses";
  BaseRouteName2["FAST_GAMES"] = "fastgames";
  BaseRouteName2["FAST_GAMES_ITEM"] = "fastgames-item";
  BaseRouteName2["LOYALTY_PROGRAM"] = "loyalty-program";
  BaseRouteName2["SHOP_ITEM_DETAILS"] = "shop-item-details";
  BaseRouteName2["VERIFY_EMAIL"] = "verify-email";
  BaseRouteName2["VERIFY_EMAIL_STATUS_PAGE"] = "verify-email-status";
  BaseRouteName2["ONBOARDING"] = "onboarding";
  BaseRouteName2["PARTNERS"] = "partners";
  BaseRouteName2["PARTNER_DETAILS"] = "partner-details";
  BaseRouteName2["FORTUNE_WHEEL"] = "fortune-wheel";
  BaseRouteName2["REFERRAL_PROGRAM"] = "referral-program";
  BaseRouteName2["REFERRAL_PROGRAM_INFO"] = "referral-program-info";
  BaseRouteName2["REFERRAL_REGISTRATION"] = "referral-registration";
  BaseRouteName2["AFFILIATE_PROFILE"] = "affiliate-profile";
  BaseRouteName2["AFFILIATE_HISTORY_OPERATIONS"] = "affiliate-history-operations";
  BaseRouteName2["AFFILIATE_HISTORY_OPERATIONS_DETAILS"] = "affiliate-history-operations-details";
  BaseRouteName2["AFFILIATE_REGISTRATIONS"] = "affiliate-registrations";
  BaseRouteName2["AFFILIATE_BONUS_CODES"] = "affiliate-bonus-codes";
  BaseRouteName2["AFFILIATE_BONUS_CODES_HELP"] = "how-to-use-bonus-codes";
  BaseRouteName2["AFFILIATE_PROGRAM"] = "affiliate-program";
  BaseRouteName2["AFFILIATE_PROGRAM_RULES"] = "affiliate-program-rules";
  BaseRouteName2["AFFILIATE_LINK_HELP"] = "how-to-use-affiliate-link";
  BaseRouteName2["AFFILIATE_PROFILE_MIGRATED"] = "affiliate-profile-migrated";
  BaseRouteName2["CMS"] = "cms";
  BaseRouteName2["CMS_SPORT_BETTING_RULES"] = "SPORT_BETTING_RULES_WEB2";
  BaseRouteName2["CMS_PROMO_TERMS"] = "PROMO_TERMS_WEB2";
  BaseRouteName2["CMS_DATA_PROCESSING_AGREEMENT"] = "DATA_PROCESSING_AGREEMENT_WEB2";
  BaseRouteName2["CMS_BETTING_PARTICIPATION_AGREEMENT"] = "BETTING_PARTICIPATION_AGREEMENT_WEB2";
  BaseRouteName2["CMS_VERIFICATION_REQUIREMENTS"] = "CMS_VERIFICATION_REQUIREMENTS";
  BaseRouteName2["ABOUT_COMPANY_WEB2"] = "ABOUT_COMPANY_WEB2";
  BaseRouteName2["AFFILIATE_PROGRAM_RULES_WEB2"] = "AFFILIATE_PROGRAM_RULES_WEB2";
  BaseRouteName2["BETTING_TERMS_WEB2"] = "BETTING_TERMS_WEB2";
  BaseRouteName2["BONUS_PROGRAM_CONDITIONS_WEB2"] = "BONUS_PROGRAM_CONDITIONS_WEB2";
  BaseRouteName2["CMS_BONUS_WALLET_ATTACHMENTS"] = "CMS_BONUS_WALLET_ATTACHMENTS";
  BaseRouteName2["CMS_HOW_BONUS_WALLET_WORKS"] = "CMS_HOW_BONUS_WALLET_WORKS";
  BaseRouteName2["CMS_N_PLUS_M"] = "CMS_N_PLUS_M";
  BaseRouteName2["RESPONSIBLE_GAMBLING_WEB2"] = "RESPONSIBLE_GAMBLING_WEB2";
  BaseRouteName2["RESPONSIBLE_GAMBLING_WEB2_MODAL"] = "RESPONSIBLE_GAMBLING_WEB2_MODAL";
  BaseRouteName2["SPORT_BETTING_RULES_WEB2"] = "SPORT_BETTING_RULES_WEB2";
  BaseRouteName2["TELEGRAM_BOT"] = "telegram-bot";
  BaseRouteName2["CMS_HOW_TO_BET"] = "HOW_TO_BET_WEB2";
  BaseRouteName2["CMS_LEGAL_DOCUMENTS"] = "LEGAL_DOCUMENTS_WEB2";
  BaseRouteName2["CMS_SECURITY_AND_PRIVACY"] = "SECURITY_AND_PRIVACY_WEB2";
  BaseRouteName2["CONTACTS"] = "contacts";
  BaseRouteName2["ACCESS_OPTIONS"] = "access-options";
  BaseRouteName2["ACCESS_OPTIONS_PERSONAL_LINK"] = "access-options-personal-link";
  BaseRouteName2["ACCESS_OPTIONS_ANDROID_APP"] = "access-options-android-app";
  BaseRouteName2["ACCESS_OPTIONS_TELEGRAM_CHANNEL"] = "access-options-telegram-channel";
  BaseRouteName2["ACCESS_OPTIONS_ADD_BOOKMARK"] = "access-options-add-bookmark";
  BaseRouteName2["ACCESS_OPTIONS_CALLBACK"] = "access-options-callback";
  BaseRouteName2["WEBSITE_ACCESS"] = "website-access";
  BaseRouteName2["RULES"] = "rules";
  BaseRouteName2["DEVTOOLS"] = "dev-tools";
  BaseRouteName2["REGISTRATION"] = "registration";
  BaseRouteName2["UNTRUSTED_DEVICE"] = "untrusted-device";
  BaseRouteName2["SPORTRADAR_STATISTICS_WIDGET"] = "sportradar-statistics-widget";
  BaseRouteName2["SPORTRADAR_LIVESCORE_WIDGET"] = "sportradar-livescore-widget";
  BaseRouteName2["ANDROID"] = "android";
  BaseRouteName2["COUPONS"] = "my-coupons";
  BaseRouteName2["EURO_2024"] = "euro-2024";
  BaseRouteName2["EURO_2024_BATTLE_PASS"] = "euro-2024-battle-pass";
  BaseRouteName2["EURO_2024_BONUS_INFO"] = "euro-2024-bonus-info";
  BaseRouteName2["VIP"] = "vip";
  BaseRouteName2["VIP_EXPRESS"] = "vip-express";
  BaseRouteName2["BONUS_CALENDAR"] = "bonus-calendar";
  BaseRouteName2["BONUS_CALENDAR_PRIZES"] = "bonus-calendar-prizes";
  BaseRouteName2["LANGUAGE"] = "language";
  BaseRouteName2["PIN_CODE"] = "pin-code";
  BaseRouteName2["PIN_CODE_PROFILE"] = "pin-code-profile";
  BaseRouteName2["REFERRAL_PROGRAM_AGREEMENT"] = "referral-program-agreement";
  return BaseRouteName2;
})(BaseRouteName || {});
var RegistrationRouteName = /* @__PURE__ */ ((RegistrationRouteName2) => {
  RegistrationRouteName2["REGISTRATION"] = "registration";
  RegistrationRouteName2["SIMPLE_REGISTRATION_PHONE_CHECK"] = "registration-phone-check";
  RegistrationRouteName2["SIMPLE_REGISTRATION_CHANGE_PASSWORD"] = "registration-change-password";
  RegistrationRouteName2["SIMPLE_REGISTRATION_CHANGE_PASSWORD_SUCCESS"] = "registration-change-password-success";
  RegistrationRouteName2["SIMPLE_REGISTRATION_CHANGE_PHONE"] = "registration-change-phone";
  RegistrationRouteName2["SIMPLE_REGISTRATION_SUCCESS"] = "registration-success";
  return RegistrationRouteName2;
})(RegistrationRouteName || {});
var RegistrationCompletionRouteName = /* @__PURE__ */ ((RegistrationCompletionRouteName2) => {
  RegistrationCompletionRouteName2["REGISTRATION_COMPLETION"] = "registration-completion";
  return RegistrationCompletionRouteName2;
})(RegistrationCompletionRouteName || {});
const RouteName = /* @__PURE__ */ (() => ({
  ...BaseRouteName,
  ...SportlineRouteName,
  ...PromoRouteName,
  ...RegistrationRouteName,
  ...RegistrationCompletionRouteName,
  ...AccountHistoryRouteName,
  ...ProfileRouteName
}))();
const CasinoRouteName = {
  CASINO_LOBBY: "casino-lobby",
  CASINO_GROUPS: "casino-groups",
  CASINO_GAME: "casino-game",
  CASINO_LIVE_LOBBY: "casino-live-lobby",
  CASINO_LIVE_GROUPS: "casino-live-groups",
  CASINO_LIVE_GAME: "casino-live-game-v2",
  CASINO_SEARCH: "casino-search",
  CASINO_BETGAMES: "casino-betgames",
  CASINO_LOYALTY_PROGRAM: "casino-loyalty-program",
  CASINO_LOYALTY_PROGRAM_ID: "casino-loyalty-program-id",
  CASINO_LOYALTY_PROGRAM_INFO: "casino-loyalty-program-info",
  GAME_EXIT: "game-exit",
  WEB2_CASINO_RULES: "WEB2_CASINO_RULES",
  CASINO_MY_GAMES: "casino-my-games"
};
const casinoRouteNames = /* @__PURE__ */ Object.values(CasinoRouteName);
function isCasinoRouteName(value) {
  return casinoRouteNames.includes(value);
}
function isEGSRoute(routeName, isEGSHomePageType) {
  if (process.env.VUE_APP_FEATURE_CASINO_DISABLED) {
    return false;
  }
  return isCasinoRouteName(routeName) || isEGSHomePageType && routeName === RouteName.HOME;
}
function isSportlineRoute(routeName, isSportHomePageType) {
  if (process.env.VUE_APP_FEATURE_SPORTLINE_DISABLED) {
    return false;
  }
  return isSportlineRouteName(routeName) || isSportHomePageType && routeName === RouteName.HOME;
}
var TsupisRouteName = /* @__PURE__ */ ((TsupisRouteName2) => {
  TsupisRouteName2["ACCEPT_TSUPIS_OFFER_AGREEMENT"] = "accept-tsupis-offer-agreement";
  TsupisRouteName2["ACCEPT_TSUPIS_PERSONAL_DATA_AGREEMENT"] = "accept-tsupis-personal-data-agreement";
  TsupisRouteName2["IDENT_GENERAL_INFO_WEB2"] = "IDENT_GENERAL_INFO_WEB2";
  TsupisRouteName2["ESIA_NATIVE_REDIRECT_TARGET"] = "esia-login";
  TsupisRouteName2["ESIA_ETSUPIS_REDIRECT_TARGET"] = "esia-etsupis-redirect-target";
  return TsupisRouteName2;
})(TsupisRouteName || {});
const baseRouteNameNames = /* @__PURE__ */ Object.values(RouteName);
function isBaseRouteName(value) {
  return baseRouteNameNames.includes(value);
}
export {
  BaseRouteName,
  CasinoRouteName,
  ProfileRouteName,
  PromoRouteName,
  RegistrationRouteName,
  RouteName,
  SportlineRouteName,
  TsupisRouteName,
  isBaseRouteName,
  isCasinoRouteName,
  isEGSRoute,
  isProfileRouteName,
  isPromoRouteName,
  isSportlineRoute,
  isSportlineRouteName
};
