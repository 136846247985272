import { updateEnvironment } from "@leon-hub/environment-client";
import { AppError } from "@leon-hub/app-errors";
function getDefinedEnvironment() {
  return __ENVIRONMENT__;
}
function checkEnvironmentIsClean(environment) {
  const keys = Object.keys(environment);
  if (keys.length === 0 || process.env.NODE_ENV === "test") {
    return true;
  }
  for (const key of keys) {
    console.warn(
      `Unexpected configured environment key '${key}'=='${environment[key]}'. These will lead to unexpected behaviour.`
    );
  }
  return false;
}
function preventChange(key) {
  const error = new AppError({
    message: `EnvironmentPlugin has not been initialized, unable to get/access process.env['${String(key)}']`
  });
  console.error(error);
}
const bootstrapEnvironment = () => {
  if (process.env.VUE_APP_BUNDLER_WEBPACK) {
    checkEnvironmentIsClean(process.env);
  }
  const rawProcessEnvironment = {
    ...process.env,
    ...getDefinedEnvironment()
  };
  const environment = { ...rawProcessEnvironment };
  updateEnvironment({
    environment
  });
  const rawKeys = Object.keys(rawProcessEnvironment);
  function isUpdateAllowed(key) {
    if (rawKeys.includes(String(key))) {
      preventChange(key);
      return false;
    }
    return true;
  }
  if (rawProcessEnvironment.NODE_ENV === "test") {
    process.env = rawProcessEnvironment;
  } else {
    const proxy = new Proxy(process.env, {
      get(target, rawKey) {
        return environment[String(rawKey)];
      },
      deleteProperty(target, key) {
        if (isUpdateAllowed(key)) {
          delete environment[String(key)];
          return true;
        }
        return false;
      },
      set(target, key, value) {
        if (isUpdateAllowed(key)) {
          Object.defineProperty(environment, String(key), { value, configurable: true });
          return true;
        }
        return false;
      }
    });
    process.env = proxy;
  }
};
const EnvironmentPlugin = {
  install(app) {
    app.mixin({
      computed: {
        process: () => ({
          env: process.env
        })
      }
    });
  }
};
export {
  EnvironmentPlugin,
  bootstrapEnvironment,
  getDefinedEnvironment
};
