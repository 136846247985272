import { getBaseHeaders, resolveApi2Url } from '@leon-hub/api';

import ApplicationRestClient from 'web/src/modules/core/services/api/ApplicationRestClient';

export default class DefaultRestApiClient extends ApplicationRestClient {
  constructor() {
    super({
      method: 'GET',
      baseUrl: resolveApi2Url(),
      headers: getBaseHeaders(),
    });
  }
}
