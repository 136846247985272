import type { Ref } from 'vue';
import {
  watch,
} from 'vue';

export function useHtmlClassList(className: Ref<string[]>): void {
  watch(className, (newValue) => {
    window.document.documentElement.setAttribute('class', newValue.join(' '));
  }, { immediate: true, deep: true });
}
